import React, { useState } from "react";

const UserTableFilters = ({ data, setRows, searchKey }) => {
  const [search, setSearch] = useState("");

  const handleSearch = (value) => {
    const searchData = data.filter((ele) =>
      ele[searchKey].toLowerCase().includes(value.toLowerCase())
    );
    setRows(searchData);
    setSearch(value);
  };

  return (
    <div className="body">
      <div className="row clearfix">
        <div className="col-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Search</span>
            </div>
            <input
              type={"text"}
              placeholder={`Search...`}
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTableFilters;
