import React from "react";
import { Link, useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";

// hooks
import { useGetPaper, useGetPunjabPaper } from "hooks/api/paper";

import PaperFormat from "components/PaperFormat/index";

const PaperDetail = () => {
  const { id } = useParams();
  const { data: paper } = useGetPaper(id);
  const { data: punjabPaper } = useGetPunjabPaper(id);

  return (
    <div className="container-fluid">
      <PageHeader
        Breadcrumb={[
          { name: "Papers", navigate: "/papers" },
          { name: "Detail" },
        ]}
      />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Paper Detail</h2>

          <Link to={`/papers/${id}/print`}>
            <button
              type="button"
              className="btn btn-success"
              title="Print Paper"
            >
              <span className="sr-only">Print Paper</span>
              <i className="fa fa-plus mr-1"></i> <span>Print Paper</span>
            </button>
          </Link>
        </div>
        <div id="exportContent">
          <PaperFormat data={punjabPaper?.data} showMcqAnswer={true} />
        </div>
      </div>
    </div>
  );
};

export default PaperDetail;
