import React from "react";


// components
import PageHeader from "components/BreadCrumb";
import TutorialPDF from "../../assets/pdf/Tutorial-RateReviews.pdf"

export default function RateReview() {
  return (
    <div className="container-fluid">
      <PageHeader
        Breadcrumb={[
          { name: "Tutorials", navigate: "/taketour/RateReview" },
          { name: "Rate & Review" },
        ]}
      />
      <div className="card">
        <div className="header">
          <h2>Rate & Review</h2>
        </div>
        <div className="body">
          <p>This is a pdf tutorial of how to Rate and review in the system  </p>
          <iframe width="1000" height="800" src={TutorialPDF} title="Tutorual - How to lodge a Complaint" frameborder="0" allow="accelerometer; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  );
}
