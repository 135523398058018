// context
import { useAppContext } from "context/app.context";

export function useToaster() {
  const { dispatch } = useAppContext();

  const toaster = ({ message, severity }) => {
    dispatch("Toaster", {
      message,
      open: true,
      severity,
    });
  };

  const toasterError = (message) => {
    toaster({ message, severity: "error" });
  };

  const toasterSuccess = (message) => {
    toaster({ message, severity: "success" });
  };

  return { toasterError, toasterSuccess };
}
