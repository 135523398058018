// third party
import { useQuery, useMutation } from "@tanstack/react-query";

// hooks
import {
  getAskQuestions,
  getRateReviews,
  getComplaints,
  updateComplaintStatus,
  updateAskQuestionRemarks,
} from "api/v1/feedback";

// custom hook
import { useToaster } from "hooks/custom/useToaster";

export const useGetAskQuestions = () => {
  return useQuery({
    queryKey: ["admin-ask-questions"],
    queryFn: () => getAskQuestions(),
    refetchOnWindowFocus: false,
  });
};

export const useGetRateReviews = () => {
  return useQuery({
    queryKey: ["admin-rate-reviews"],
    queryFn: () => getRateReviews(),
    refetchOnWindowFocus: false,
  });
};

export const useGetComplaints = () => {
  return useQuery({
    queryKey: ["admin-complaints"],
    queryFn: () => getComplaints(),
    refetchOnWindowFocus: false,
  });
};

export const useUpdateComplaintsStatus = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateComplaintStatus,
    onSuccess: () => {
      refetch();
      toasterSuccess("Complaints Resolved!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useUpdateAskQuestionRemarks = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateAskQuestionRemarks,
    onSuccess: () => {
      refetch();
      toasterSuccess("Remarks Updated!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};
