import client from "../api-config";

export function createCampus(body) {
  return client.post("/api/campus/add", body);
}

export function getCampuses() {
  return client.get("/api/campuses");
}

export function getCampus(id) {
  return client.get(`/api/campus/${id}`);
}

export function updateCampus(data) {
  return client.put(`/api/campuses/${data.id}`, { ...data.body });
}

export function deleteCampus(id) {
  return client.delete(`/api/campuses/${id}`);
}
