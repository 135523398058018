import React, { useState, useEffect } from "react";

// components
import CustomPagination from "components/CustomPagination ";

// packages
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

// hooks
import { useGetMCQS } from "hooks/api/subject";
import { useTable } from "hooks/custom/useTable";

const MCQSModal = ({
  board_id,
  class_id,
  subject_id,
  selectedChapters,
  selectedMcqs,
  setSelectedMcqs,
  btnDisabled,
}) => {
  const [modal, setModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { data: mcqs } = useGetMCQS();

  useEffect(() => {
    if (mcqs?.data) {
      const data = filterArray({ board_id, class_id, subject_id }, mcqs?.data);
      setRows(
        data.filter((ele) =>
          selectedChapters?.length > 0
            ? selectedChapters.includes(ele.chapter_id) && ele.status == 1
            : ele.status == 1
        )
      );
    }
  }, [
    mcqs?.data,
    board_id,
    class_id,
    subject_id,
    JSON.stringify(selectedChapters),
  ]);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const handleClose = () => setModal(false);

  const isMath = ["5", "12"].includes(`${subject_id}`);
  return (
    <>
      <button
        className="btn btn-primary"
        style={{ width: "100%" }}
        onClick={() => setModal(true)}
        disabled={btnDisabled}
      >
        Select MCQS
      </button>

      <Modal size="xl" centered show={modal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Select MCQS</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="body table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>MCQS</th>
                  <th>CHAPTER</th>
                  <th>TOPIC</th>
                </tr>
              </thead>
              <tbody>
                {rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <tr key={row.id}>
                      <td style={{ minWidth: "60px", width: "60px" }}>
                        <div className="form-group">
                          <label className="fancy-checkbox">
                            <input
                              name="checkbox"
                              type="checkbox"
                              checked={
                                selectedMcqs.filter(
                                  (ele) => ele?.id === row?.id
                                ).length > 0
                                  ? true
                                  : false
                              }
                              onChange={(value) => {
                                const exist = selectedMcqs.filter(
                                  (ele) => ele.id === row.id
                                );

                                if (exist.length > 0) {
                                  setSelectedMcqs(
                                    selectedMcqs.filter(
                                      (ele) => ele.id !== row.id
                                    )
                                  );
                                } else {
                                  setSelectedMcqs([...selectedMcqs, row]);
                                }
                              }}
                            />
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        {isMath ? (
                          <MathJax math={`${row.description}`} />
                        ) : (
                          parse(`${row.description}`)
                        )}
                      </td>
                      {/* {!!image && image != "" && (
          <span>
            <img
              src={`${IMAGE_URL}/mcqs/${image}`}
              alt="image"
              style={{ maxWidth: "100%" }}
            />
          </span>
        )} */}
                      <td>{row.chapter_name}</td>
                      <td>{row.topic_name}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
            <CustomPagination
              count={rows.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MCQSModal;

const removeNullValues = (obj) => {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
  return obj;
};

const filterArray = (filterObj, data) => {
  const obj = removeNullValues({ ...filterObj });

  const filteredArray = data.filter((item) => {
    let match = true;
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (item[key] != obj[key]) {
          match = false;
          break;
        }
      }
    }
    return match;
  });

  return filteredArray;
};
