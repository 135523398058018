import React, { useEffect, useState } from "react";

// components
import PageHeader from "components/BreadCrumb";
import CustomPagination from "components/CustomPagination ";
import AddRemarks from "components/Modal/AddRemarks";

// context
import { useAuthContext } from "context/auth.context";

// hooks
import { useTable } from "hooks/custom/useTable";
import {
  useGetAskQuestions,
  useUpdateAskQuestionRemarks,
} from "hooks/api/admin-feedback";

//
import { USER_ROLES } from "constant";
import { formatDate } from "utils/Helper";
import { CSVLink } from "react-csv";

const Complaint = () => {
  const { user } = useAuthContext();
  const isAdmin = USER_ROLES.admin == user?.rolename?.toLowerCase();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const { data: askQuestions, refetch } = useGetAskQuestions();
  const askQuestionRemarks = useUpdateAskQuestionRemarks(refetch);

  const addRemarks = async (id, remarks) => {
    await askQuestionRemarks.mutateAsync({ id, body: { remarks } });
  };

  useEffect(() => {
    if (askQuestions?.data) {
      setRows(
        isAdmin
          ? askQuestions?.data
          : askQuestions?.data?.filter(({ user_id }) => user_id == user.id)
      );
      setLoading(false);
    }
  }, [askQuestions?.data]);

  return (
    <div className="container-fluid">
      <PageHeader Breadcrumb={[{ name: "Ask Questions" }]} />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>All Ask Questions</h2>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {rows && rows.length > 0 ? (
              <CSVLink filename={"all-questions.csv"} data={rows}>
                {" "}
                <button type="button" className="btn btn-primary">
                  <span className="mx-1">Export Questions</span>
                  <i className="fa fa-download"></i>
                </button>
              </CSVLink>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>USER ID</th>
                  <th>Date & Time</th>
                  <th>USER NAME</th>
                  <th>USER EMAIL</th>
                  <th>USER GROUP</th>
                  <th>USER CAMPUS</th>
                  <th>Question</th>
                  <th style={{ maxWidth: "200px" }}>Remarks</th>
                  {isAdmin && <th style={{ textAlign: "end" }}>Action</th>}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "center",
                        height: "30vh",
                        width: "100%",
                      }}
                      className="visible-lg"
                    >
                      <i className="fa fa-spinner fa-4x fa-spin"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <>
                    {rows?.length > 0 ? (
                      rows
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row) => (
                          <tr key={row.id}>
                            <th scope="row">{row.user_id}</th>
                            <td>{formatDate(row?.created_at)}</td>
                            <td>{row.user_name}</td>
                            <td>{row.user_email}</td>
                            <td>{row.user_group_name}</td>
                            <td>{row.campus_name}</td>
                            <td>{row?.question}</td>
                            <td>{row?.remarks}</td>
                            {isAdmin && (
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <AddRemarks
                                    data={row}
                                    addRemarks={addRemarks}
                                  />
                                </div>
                              </td>
                            )}
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                            height: "30vh",
                            fontSize: "1.5rem",
                            width: "100%",
                          }}
                        >
                          Oops! No data found
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Complaint;
