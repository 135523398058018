import React from "react";
export const AppContext = React.createContext();

export function AppProvider({ children }) {
  const [state, setState] = React.useState({});

  const dispatch = (actionType, payload) => {
    switch (actionType) {
      case "Toaster":
        setState({ ...state, toaster: payload });
        return;
      case "CHAPTER_FILTERS":
        setState({ ...state, CHAPTER_FILTERS: payload });
        return;
      case "TOPIC_FILTERS":
        setState({ ...state, TOPIC_FILTERS: payload });
        return;
      case "QUESTION_FILTERS":
        setState({ ...state, QUESTION_FILTERS: payload });
        return;
      case "MCQS_FILTERS":
        setState({ ...state, MCQS_FILTERS: payload });
        return;
      default:
        return;
    }
  };

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = React.useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAppContext can only be used inside AppProvider");
  }

  return context;
}
