import React from "react";
import BImg from "../../assets/images/Banner-stats.jpg";

// components
import PageHeader from "../../components/BreadCrumb";

function AboutUs() {
  return (
    <div className="container-fluid">
      <PageHeader Breadcrumb={[{ name: "About US" }]} />
      <div className="card">
        <div className="body">
          <img src={BImg} alt="" width="1080px" />
        </div>
      </div>
      <div className="card">
        <div className="body">
          <div>
            <main>
              <section>
                <h2>Introduction</h2>
                <p>
                  The dynamic nature of technology is continuously reshaping the
                  way we work, create, and communicate. Notably, the progress in
                  digital technology is opening up transformative opportunities
                  in the field of education. In light of the ongoing global
                  digital transformation, THE EDUCATORS and Beaconhouse School
                  System are thrilled to introduce QuizillaPro - Question Paper
                  Generator for our Secondary Schools. This innovative web-based
                  application is designed to assist teachers in schools,
                  offering them a time-saving solution for generating question
                  papers.
                </p>
              </section>

              <section>
                <h2>Features</h2>
                <p>
                  QuizillaPro offers a range of features to enhance the user
                  experience and streamline the process of generating question
                  papers:
                </p>
                <ul>
                  <li>Paper Generation</li>
                  <li>Question Bank</li>
                  <li>Instant Download and Printing</li>
                  <li>Classroom Assessment</li>
                  <li>Complaint Management System</li>
                  <li>Evaluating and Monitoring Reporting System</li>
                </ul>
              </section>

              <section>
                <p>
                  In essence, QuizillaPro endeavours to offer users a seamless
                  experience throughout the entire process by providing an
                  intuitive and efficient interface. This interface allows users
                  to effortlessly navigate, configure, generate, and manage
                  question papers, ensuring a smooth and hassle-free experience.
                </p>
              </section>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
