import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import FormSelect from "components/FormField/Select";
import FormTextEditor from "components/FormField/TextEditor";
import ImageFile from "components/FormField/ImageFile";

// packages
import { useForm } from "react-hook-form";
import _ from "lodash";

// api's hook
import {
  useGetBoards,
  useGetChapters,
  useGetClasses,
  useGetSubjects,
  useGetTopics,
  useCreateMCQS,
  useGetMCQ,
  useUpdateMCQS,
} from "hooks/api/subject";
import { IMAGE_URL } from "constant";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateQuestion = () => {
  const { id } = useParams();

  const [valueAdded, setValueAdded] = React.useState(false);
  const [render, setRender] = React.useState(id ? false : true);
  const [image, setImage] = React.useState({});

  const { data: mcq } = useGetMCQ(id);
  const { data: boards } = useGetBoards();
  const { data: classes } = useGetClasses();
  const { data: subjects } = useGetSubjects();
  const { data: chapters } = useGetChapters();
  const { data: topics } = useGetTopics();

  const createMCQS = useCreateMCQS();
  const updateMCQS = useUpdateMCQS();

  const { handleSubmit, reset, watch, setValue, control, formState } =
    useForm();

  const handleImageChange = (event, option) => {
    const file = event.target.files[0];
    setImage({
      ...image,
      [option]: {
        file,
        name: file.name,
        previewUrl: URL.createObjectURL(file),
      },
    });
  };
  const handleRemoveImage = (option) => {
    setImage({
      ...image,
      [option]: {},
    });
  };

  async function onSubmit(data) {
    try {
      let body = _.pick(data, [
        "boardId",
        "classId",
        "subjectId",
        "chapterId",
        "topicId",
        "description",
        "optA",
        "optB",
        "optC",
        "optD",
        "optAns",
      ]);

      const formData = new FormData();
      formData.append(
        `description_image`,
        image?.description_image?.file || null
      );
      formData.append(`opt_a_file`, image?.opt_a?.file || null);
      formData.append(`opt_b_file`, image?.opt_b?.file || null);
      formData.append(`opt_c_file`, image?.opt_c?.file || null);
      formData.append(`opt_d_file`, image?.opt_d?.file || null);
      Object.entries(body).map(([key, value], index) =>
        formData.append(`${key}`, value)
      );

      if (id) {
        formData.append(
          `status`,
          mcq?.data[0]?.status == 2 || mcq?.data[0]?.status == 3
            ? 3
            : mcq?.data[0]?.status
        );

        await updateMCQS.mutateAsync({
          id,
          body: formData,
        });
      } else {
        formData.append(`status`, 0);
        await createMCQS.mutateAsync(formData);
      }
    } catch (err) {}
  }

  const watchBoard = watch("boardId");
  const watchClass = watch("classId");
  const watchSubject = watch("subjectId");
  const watchChapter = watch("chapterId");

  useEffect(() => {
    valueAdded && setValue("chapterId", "");
  }, [watchBoard, watchClass, watchSubject]);

  useEffect(() => {
    valueAdded && setValue("topicId", "");
  }, [watchBoard, watchClass, watchSubject, watchChapter]);

  useEffect(() => {
    if (
      !!id &&
      !!render &&
      !!Object.values(watch()).length > 0 &&
      mcq?.data?.[0]
    ) {
      setValueAdded(true);
    }

    if (!id && !!render) {
      setValueAdded(true);
    }
  }, [watch()]);

  const chaptersOptions = useMemo(() => {
    let temp = [];
    if (watchBoard && watchClass && watchSubject) {
      for (let i = 0; i < chapters?.data?.length; i++) {
        if (
          chapters?.data[i].board_id == watchBoard &&
          chapters?.data[i].class_id == watchClass &&
          chapters?.data[i].subject_id == watchSubject
        ) {
          temp.push({
            label: chapters?.data[i].name,
            value: chapters?.data[i].id,
          });
        }
      }
    }
    return temp;
  }, [chapters?.data, watchBoard, watchClass, watchSubject]);

  const topicOptions = useMemo(() => {
    let temp = [];
    if (watchChapter) {
      for (let i = 0; i < topics?.data?.length; i++) {
        if (
          topics?.data[i].board_id == watchBoard &&
          topics?.data[i].class_id == watchClass &&
          topics?.data[i].subject_id == watchSubject &&
          topics?.data[i].chapter_id == watchChapter
        ) {
          temp.push({
            label: topics?.data[i].name,
            value: topics?.data[i].id,
          });
        }
      }
    }
    return temp;
  }, [topics?.data, watchChapter]);

  React.useEffect(() => {
    if (mcq?.data?.[0]) {
      let values = _.pick(mcq?.data?.[0], [
        "board_id",
        "class_id",
        "subject_id",
        "chapter_id",
        "topic_id",
        "times",
        "description",
        "file",
        "opt_a",
        "opt_b",
        "opt_c",
        "opt_d",
        "opt_a_file",
        "opt_b_file",
        "opt_c_file",
        "opt_d_file",
        "option_answer",
        "status",
      ]);

      let formValues = {
        boardId: values.board_id,
        classId: values.class_id,
        subjectId: `${values.subject_id}`,
        chapterId: values.chapter_id,
        topicId: values.topic_id,
        times: values.times,
        description: values.description,
        optA: values.opt_a,
        optB: values.opt_b,
        optC: values.opt_c,
        optD: values.opt_d,
        optAns: values.option_answer,
        // status: !!mcq?.data[0]?.feeback ? 1 : 0,
        status: mcq?.data[0]?.status,
      };

      setImage({
        description_image:
          !!values?.file && values?.file != ""
            ? {
                previewUrl: `${IMAGE_URL}/mcqs/${values.file}`,
              }
            : null,
        opt_a:
          !!values?.opt_a_file && values?.opt_a_file != ""
            ? {
                previewUrl: `${IMAGE_URL}/mcqs/${values.opt_a_file}`,
              }
            : null,
        opt_b:
          !!values?.opt_b_file && values?.opt_b_file != ""
            ? {
                previewUrl: `${IMAGE_URL}/mcqs/${values.opt_b_file}`,
              }
            : null,
        opt_c:
          !!values?.opt_c_file && values?.opt_c_file != ""
            ? {
                previewUrl: `${IMAGE_URL}/mcqs/${values.opt_c_file}`,
              }
            : null,
        opt_d:
          !!values?.opt_d_file && values?.opt_d_file != ""
            ? {
                previewUrl: `${IMAGE_URL}/mcqs/${values.opt_d_file}`,
              }
            : null,
      });

      Object.entries(formValues).forEach(([key, val]) => {
        setValue(`${key}`, val);
      });

      setRender(true);
    }
  }, [mcq]);

  // const isCKEditor = ["5", "6", "8"].includes(watchSubject);
  const isCKEditor =
    [5, 6, 8, 12, 13, 15].filter((id) => id == Number(watchSubject)).length > 0
      ? true
      : false;

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create MCQS"
        Breadcrumb={[
          { name: "MCQS", navigate: "/mcqs" },
          { name: id ? "Edit" : "Create" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>{id ? "Edit" : "Create"} MCQS</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormSelect
                control={control}
                error={formState?.errors?.boardId}
                item={{
                  name: "boardId",
                  label: "Board",
                  options: boards?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.classId}
                item={{
                  name: "classId",
                  label: "Class",
                  options: classes?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.subjectId}
                item={{
                  name: "subjectId",
                  label: "Subject",
                  options: subjects?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.chapterId}
                item={{
                  name: "chapterId",
                  label: "Chapter",
                  options: chaptersOptions,
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.topicId}
                item={{
                  name: "topicId",
                  label: "Topic",
                  options: topicOptions,
                  // rules,
                }}
              />
              {/* <FormTextField
                control={control}
                error={formState?.errors?.times}
                item={{
                  type: "number",
                  name: "times",
                  label: "Time",
                  placeholder: "Enter Time",
                  rules,
                }}
              /> */}

              <div className="col-12 mb-4">
                <FormTextEditor
                  isCKEditor={isCKEditor}
                  control={control}
                  error={formState?.errors?.description}
                  item={{
                    name: "description",
                    label: "MCQS",
                    required: true,
                  }}
                />
              </div>

              <ImageUploadAndView
                rootCSS={"col-12"}
                image={image?.description_image || {}}
                onChangeImage={(e) => handleImageChange(e, "description_image")}
                onRemoveImage={() => handleRemoveImage("description_image")}
              />

              <div className="col-6 mb-4">
                <FormTextEditor
                  isCKEditor={isCKEditor}
                  control={control}
                  error={formState?.errors?.optA}
                  item={{
                    name: "optA",
                    label: "Option A",
                    required: true,
                  }}
                />
              </div>

              <ImageUploadAndView
                image={image?.opt_a || {}}
                onChangeImage={(e) => handleImageChange(e, "opt_a")}
                onRemoveImage={() => handleRemoveImage("opt_a")}
              />

              <div className="col-6 mb-4">
                <FormTextEditor
                  isCKEditor={isCKEditor}
                  control={control}
                  error={formState?.errors?.optB}
                  item={{
                    name: "optB",
                    label: "Option B",
                    required: true,
                  }}
                />
              </div>
              <ImageUploadAndView
                image={image?.opt_b || {}}
                onChangeImage={(e) => handleImageChange(e, "opt_b")}
                onRemoveImage={() => handleRemoveImage("opt_b")}
              />

              <div className="col-6 mb-4">
                <FormTextEditor
                  isCKEditor={isCKEditor}
                  control={control}
                  error={formState?.errors?.optC}
                  item={{
                    name: "optC",
                    label: "Option C",
                    required: true,
                  }}
                />
              </div>
              <ImageUploadAndView
                image={image?.opt_c || {}}
                onChangeImage={(e) => handleImageChange(e, "opt_c")}
                onRemoveImage={() => handleRemoveImage("opt_c")}
              />

              <div className="col-6 mb-4">
                <FormTextEditor
                  isCKEditor={isCKEditor}
                  control={control}
                  error={formState?.errors?.optD}
                  item={{
                    name: "optD",
                    label: "Option D",
                    required: true,
                  }}
                />
              </div>
              <ImageUploadAndView
                image={image?.opt_d || {}}
                onChangeImage={(e) => handleImageChange(e, "opt_d")}
                onRemoveImage={() => handleRemoveImage("opt_d")}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.optAns}
                item={{
                  name: "optAns",
                  label: "Right Option",
                  options: [
                    { value: "opt_a", label: "Option A" },
                    { value: "opt_b", label: "Option B" },
                    { value: "opt_c", label: "Option C" },
                    { value: "opt_d", label: "Option D" },
                  ],
                  rules,
                }}
              />

              {!!id && (
                <FormSelect
                  control={control}
                  error={formState?.errors?.status}
                  item={{
                    name: "status",
                    label: "Status",
                    disabled: true,
                    options: [
                      { value: 0, label: "Deactivate" },
                      { value: 1, label: "Active" },
                      { value: 2, label: "Feedback" },
                      { value: 3, label: "Resolved" },
                    ],
                    // defaultValue: !!mcq?.data[0]?.feeback && id ? "1" : "0",
                    // options: [
                    //   { value: 1, label: "Active" },
                    //   { value: 0, label: "Deactivate" },
                    // ],
                    rules,
                  }}
                />
              )}
            </div>

            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              {id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateQuestion;

const ImageUploadAndView = ({
  rootCSS,
  image,
  onChangeImage,
  onRemoveImage,
}) => {
  return (
    <div className={`col-6 mb-4 row clearfix ${rootCSS}`}>
      <div className="col-12">
        <ImageFile image={image} onChange={onChangeImage} />
      </div>
      {!!image?.previewUrl && (
        <div className="col-12" style={{ position: "relative" }}>
          <button
            type="button"
            title="Close"
            className="btn btn-warning"
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
            }}
            onClick={onRemoveImage}
          >
            <span className="sr-only">Close</span>
            <i className="fa fa-close"></i>
          </button>
          <img
            src={image?.previewUrl}
            alt="image"
            style={{ maxWidth: "100%", width: "120px" }}
          />
        </div>
      )}
    </div>
  );
};
