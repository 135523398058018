import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import CustomPagination from "components/CustomPagination ";

// hooks
import { useTable } from "hooks/custom/useTable";
import { useDeletePaper, useGetMyPapers } from "hooks/api/paper";

// context
import { useAuthContext } from "context/auth.context";

export default function MyWorkFolder() {
  const history = useHistory();
  const { user } = useAuthContext();

  const [rows, setRows] = useState([]);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const { data: papers, refetch } = useGetMyPapers();
  const deletePaper = useDeletePaper(refetch);

  useEffect(() => {
    if (papers?.data) {
      setRows(papers?.data);
    }
  }, [papers?.data]);

  return (
    <div className="container-fluid">
      <PageHeader
        Breadcrumb={[
          { name: "My Account", navigate: "/myaccount" },
          { name: "My Work Folder" },
        ]}
      />
      <div className="card">
        <div className="header">
          <h2>
            My Work Folder
            <small>All your generated papers are below:</small>
          </h2>
        </div>
      </div>

      <div className="card">
        <div className="header">
          <h2>My Papers</h2>
        </div>

        <div className="body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Paper #</th>
                  <th>NAME</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th style={{ textAlign: "end" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <tr key={row?.id}>
                      <td>{row.id} </td>
                      <td>{row.paper_title}</td>
                      <td>{row.type_name}</td>
                      <td>{new Date(row.paper_date).toDateString()}</td>
                      <td
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary mr-1"
                            title="View"
                            onClick={() => history.push(`/papers/${row.id}`)}
                          >
                            <span className="sr-only">View</span>
                            <i className="fa fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}
