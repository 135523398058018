import React from "react";

const infoStyle = { border: "1px solid black", padding: "0.1rem 1rem " };
const PaperStudentEntrySindh = ({ subjectCheck, data }) => {
  return (
    <div className={subjectCheck === 16 && `font-weight-bold`}>
      <div
        className=""
        style={{
          direction: "rtl",
          margin: "0 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className={`${
            data?.paperMcqs[0]?.subject_name == "Tadrees-e-Quran" &&
            `noto-nastaliq-arbi`
          }`}
        >
          مضمون:{" "}
          {data?.paperMcqs[0]?.subject_name == "Urdu"
            ? "اُردو"
            : data?.paperMcqs[0]?.subject_name == "Tadrees-e-Quran"
            ? "تَرْجَمَۃُ الْقُرْاۤنِ الْمَجِیْدِ ( لازمی)"
            : data?.paperMcqs[0]?.subject_name == "Islamiyat"
            ? "اِسلامیات۔لازمی"
            : data?.paperMcqs[0]?.subject_name == "Sindhi"
            ? "سنڌي"
            : data?.paperMcqs[0]?.subject_name}
        </div>
        <div>کیمپس: ________________</div>
        <div>رولنمبر:ہندسوں میں ________________</div>
      </div>
      <div
        className=""
        style={{
          direction: "rtl",
          margin: "0 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>تاریخ: ________________</div>
        <div>الفاظ میں ________________</div>
      </div>
    </div>
  );
};

export default PaperStudentEntrySindh;
