import React, { useState, useEffect } from "react";
import { Toast } from "react-bootstrap";

// context
import { useAppContext } from "context/app.context";

const Toaster = () => {
  const { state, dispatch } = useAppContext();
  const { toaster } = state || {};

  const onClose = () => dispatch("Toaster", null);

  useEffect(() => {
    if (toaster?.open) {
      setTimeout(onClose, 2000);
    }
  }, [toaster?.open]);

  if (!toaster?.open) return null;

  return (
    <Toast
      id="toast-container"
      show={toaster?.open}
      className={`toast-top-center toast-container`}
      style={{
        background: "transparent",
        overflow: "inherit",
        marginTop: "8px",
        width: "auto",
      }}
    >
      <Toast.Body
        className={`toast-${toaster?.severity}`}
        style={{ margin: "0", width: "100%", opacity: 1 }}
      >
        <strong className="mr-auto"> {toaster?.message} </strong>
        <button className="toast-close-button" onClick={onClose}>
          x
        </button>
      </Toast.Body>
    </Toast>
  );
};

export default Toaster;
