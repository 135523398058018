import React from "react";
import { useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";

// context
import { useAuthContext } from "context/auth.context";

// packages
import { useForm } from "react-hook-form";
import _ from "lodash";

// api's hook
import { useGetCampuses } from "hooks/api/campus";
import { useGetSchools } from "hooks/api/shared";
import { useGetRoles } from "hooks/api/admin";
import {
  useCreateCampusUser,
  useGetCampusUser,
  useUpdateCampusUser,
} from "hooks/api/campusUser";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateCampus = () => {
  const { id } = useParams();

  const { user: loginUser } = useAuthContext();

  const { data: campusUser } = useGetCampusUser(id);

  const { data: schools } = useGetSchools();
  const { data: roles } = useGetRoles();
  const { data: campuses } = useGetCampuses();

  const createCampusUser = useCreateCampusUser();
  const updateCampusUser = useUpdateCampusUser();

  const { handleSubmit, watch, reset, control, formState, setValue } =
    useForm();

  async function onSubmit(data) {
    try {
      const body = { ...data };

      id
        ? await updateCampusUser.mutateAsync({ id, body })
        : await createCampusUser.mutateAsync(body);

      reset();
    } catch (err) {}
  }

  React.useEffect(() => {
    if (campusUser?.data?.[0]) {
      let values = _.pick(campusUser?.data?.[0], [
        "name",
        "email",
        "roleid",
        "campusId",
        "code",
      ]);

      // Update to set role and campus code
      values = {
        ...values,
        role: values.roleid,
        campusId: values.campusId,
        code: values.code,
      };

      Object.entries(values).forEach(([key, val]) => {
        setValue(key, val, { shouldValidate: true });
      });
    }
  }, [campusUser, setValue]);

  React.useEffect(() => {
    if (!!loginUser && !!campuses?.data?.length > 0) {
      const loginUserCampus = campuses?.data?.filter(
        (ele) => ele.id == loginUser.user_campus_id
      )[0];
      setValue(`role`, 2);
      setValue(`campusId`, loginUserCampus?.id);
      setValue(`code`, loginUserCampus?.code);
    }
  }, [loginUser, campuses]);

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create New User"
        Breadcrumb={[
          { name: "New Users", navigate: "/create-new-teacher" },
          { name: id ? "Edit" : "Create" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>{id ? "Edit" : "Add"} New Teacher</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormTextField
                control={control}
                error={formState?.errors?.name}
                item={{
                  name: "name",
                  label: "Name",
                  placeholder: "Enter name",
                  rules,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.email}
                item={{
                  name: "email",
                  label: "Email",
                  placeholder: "Enter email",
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.role}
                item={{
                  name: "role",
                  label: "Role",
                  options: roles?.data?.map((role) => ({
                    value: role.id,
                    label: role.name,
                  })),
                  rules,
                  disabled: true, // Disable role select
                  // defaultValue: 2,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.campusId}
                item={{
                  name: "campusId",
                  label: "Campus",
                  options: campuses?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                  disabled: true,
                  // defaultValue: campuses?.data?.filter((ele) => ele.id == 49)[0]
                  //   ?.id,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.code}
                item={{
                  name: "code",
                  label: "Campus Code",
                  placeholder: "Enter campus code",
                  rules,
                  disabled: true,
                  // defaultValue: campuses?.data?.filter((ele) => ele.id == 49)[0]
                  //   ?.code,
                }}
              />
            </div>
            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              {id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCampus;
