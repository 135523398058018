import React from "react";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

//
import { convertToRoman } from "utils/convertToRoman";
import { IMAGE_URL } from "constant";

const MCQSCol = ({ col, number, text, image, isRightOption }) => {
  const active = isRightOption && {
    backgroundColor: "lightgreen",
    border: "1px solid green",
    borderRadius: "1px",
    color: "#fff",
    padding: "0 2px",
  };
  return (
    <p className={`${col} mcqs_cls`}>
      <span style={{ marginLeft: "1rem", fontWeight: "500", ...active }}>
        {number}
      </span>
      <span>
        <span style={{ direction: "rtl" }}>{text}</span>

        {!!image && image != "" && (
          <span>
            <img
              src={`${IMAGE_URL}/mcqs/${image}`}
              alt="image"
              style={{ maxWidth: "100%" }}
            />
          </span>
        )}
      </span>
    </p>
  );
};

export const MCQSFormat = ({
  boardType,
  is10,
  subjectCheck,
  data,
  showMcqAnswer,
}) => {
  return (
    <div className="body">
      {data?.paperMcqs?.map((ele, index) => (
        <div key={ele.id} className="row clearfix  mb-3">
          <MCQSCol
            subjectCheck={subjectCheck}
            col="col-12"
            number={`${index + 1}.`}
            text={parse(`${ele.description}`)}
            image={ele.file}
          />
          <MCQSCol
            col="col-3"
            subjectCheck={subjectCheck}
            number={`(*)`}
            text={parse(`${ele.opt_a}`)}
            image={ele.opt_a_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_a"}
          />
          <MCQSCol
            subjectCheck={subjectCheck}
            col="col-3"
            number={`(*)`}
            text={parse(`${ele.opt_b}`)}
            image={ele.opt_b_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_b"}
          />
          <MCQSCol
            subjectCheck={subjectCheck}
            col="col-3"
            number={`(*)`}
            text={parse(`${ele.opt_c}`)}
            image={ele.opt_c_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_c"}
          />
          <MCQSCol
            subjectCheck={subjectCheck}
            col="col-3"
            number={`(*)`}
            text={parse(`${ele.opt_d}`)}
            image={ele.opt_d_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_d"}
          />
        </div>
      ))}
    </div>
  );
};
