import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import EntryFeedBackField from "components/EntryFeedBackField";

// hooks
import {
  useGetChapter,
  useGetChapters,
  useReviewChapter,
} from "hooks/api/subject";

// context
import { useAuthContext } from "context/auth.context";

//
import { dataReviewUser } from "constant";

const ChapterDetail = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const history = useHistory();

  const [state, setState] = useState({});
  const [rows, setRows] = useState([]);

  const { data: chapter, refetch } = useGetChapter(id);
  const reviewChapter = useReviewChapter();
  const { data: chapters } = useGetChapters();

  useEffect(() => {
    if (chapter?.data) {
      setState({
        status: chapter?.data[0]?.review_status || 0,
        feedback: chapter?.data[0]?.feeback || "",
      });
    }
  }, [chapter]);

  useEffect(() => {
    if (chapters?.data) {
      setRows(chapters?.data);
    }
  }, [chapters]);

  const getCurrentChapterIndex = () => {
    return rows.findIndex((chapter) => chapter.id == id);
  };

  const navigateToChapter = (newIndex) => {
    if (newIndex >= 0 && newIndex < rows.length) {
      const newChapterId = rows[newIndex].id;
      history.push(`/chapters/${newChapterId}`);
    }
  };

  const onNext = () => {
    const currentIndex = getCurrentChapterIndex();
    if (currentIndex !== -1 && currentIndex < rows.length - 1) {
      navigateToChapter(currentIndex + 1);
    }
  };

  const onPrevious = () => {
    const currentIndex = getCurrentChapterIndex();
    if (currentIndex !== -1 && currentIndex > 0) {
      navigateToChapter(currentIndex - 1);
    }
  };

  const onSave = async () => {
    const body = { ...state };
    await reviewChapter.mutateAsync({ id, body });
    refetch();
  };

  return (
    <div className="container-fluid">
      <PageHeader
        Breadcrumb={[
          { name: "Chapters", navigate: "/chapters" },
          { name: "Detail" },
        ]}
      />
      <div className="card">
        <div className="header">
          <h2>Chapter Detail</h2>
        </div>

        <div className="body table-responsive">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th scope="row">Board:</th>
                <td>{chapter?.data[0].board_name}</td>
              </tr>
              <tr>
                <th scope="row">Subject:</th>
                <td>{chapter?.data[0].subjectname}</td>
              </tr>
              <tr>
                <th scope="row">Class:</th>
                <td>{chapter?.data[0].classname}</td>
              </tr>
              <tr>
                <th scope="row">Chapter #:</th>
                <td>{chapter?.data[0].lesson_no}</td>
              </tr>
              <tr>
                <th scope="row">Chapter Name:</th>
                <td>{chapter?.data[0].name}</td>
              </tr>
              <tr>
                <th scope="row">Status:</th>
                <td>{chapter?.data[0].review_status}</td>
              </tr>
              <tr>
                <th scope="row">Feedback:</th>
                <td>{chapter?.data[0].feeback}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="body">
          <button
            type="submit"
            className="btn btn-success ml-auto"
            onClick={onPrevious}
            disabled={getCurrentChapterIndex() <= 0}
          >
            Previous
          </button>
          <button
            type="submit"
            className="btn btn-success mx-3"
            onClick={onNext}
            disabled={getCurrentChapterIndex() >= rows.length - 1}
          >
            Next
          </button>
        </div>

        {user.rolename.toLowerCase() === dataReviewUser && (
          <EntryFeedBackField
            state={state}
            setState={setState}
            onSave={onSave}
          />
        )}
      </div>
    </div>
  );
};

export default ChapterDetail;
