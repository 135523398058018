import React, { useEffect } from "react";

// packages
import { Dropdown } from "react-bootstrap";
import * as echarts from "echarts";

// hooks
import { useGetBoards } from "hooks/api/subject";

// data
import { paperOverview } from "Data/paperOverview";
import { useGetPapers } from "hooks/api/paper";

const PaperOverviewGrapgh = () => {
  const { data: boards } = useGetBoards();
  const { data: papers } = useGetPapers();

  const chartPlace = () => {
    if (!!papers?.data) {
      var chartDom = document.getElementById("m_area_chart");
      var myChart = echarts.init(chartDom);
      var option;
      option = paperOverview(getData());

      option && myChart.setOption(option);
    }
  };

  useEffect(chartPlace, [papers?.data]);

  const getData = () => {
    // Group data by paper_board_type_id
    const groupedByBoardType = {};

    for (const item of papers?.data) {
      const boardTypeId = item.paper_board_type_id;

      if (!groupedByBoardType[boardTypeId]) {
        groupedByBoardType[boardTypeId] = [];
      }

      groupedByBoardType[boardTypeId].push(item);
    }

    // Get the current year
    const currentYear = new Date().getFullYear();

    // Group each group by month using the current year for created_at and fill missing months
    const result = {};
    for (const boardTypeId in groupedByBoardType) {
      const data = groupedByBoardType[boardTypeId];

      result[boardTypeId] = {};

      // Fill missing months with empty arrays
      for (let month = 1; month <= 12; month++) {
        const monthYear = `${month}/${currentYear}`;
        if (!result[boardTypeId][monthYear]) {
          result[boardTypeId][monthYear] = [];
        }
      }

      data.forEach((item) => {
        const createdDate = new Date(item.created_at);
        const monthYear = createdDate.toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
        });

        if (
          !result[boardTypeId][monthYear] &&
          createdDate.getFullYear() === currentYear
        ) {
          result[boardTypeId][monthYear] = [];
        }

        if (createdDate.getFullYear() === currentYear) {
          result[boardTypeId][monthYear].push(item);
        }
      });
    }

    return result;
  };

  return (
    <div className="card">
      <div className="header">
        <h2>Paper Overview</h2>
        {/* <Dropdown as="ul" className="header-dropdown">
          <Dropdown.Toggle variant="success" as="li" id="dropdown-basic">
            <Dropdown.Menu
              as="ul"
              className="dropdown-menu dropdown-menu-right"
            >
              {boards?.data?.map((ele) => (
                <li>
                  <a>{ele.name}</a>
                </li>
              ))}
            </Dropdown.Menu>
          </Dropdown.Toggle>
        </Dropdown> */}
      </div>
      <div className="body gender-overview">
        {/* <h5 className="pb-4">
          <span className="m-r-50">
            <i className="fa fa-male m-r-10"></i> 2,21,598
          </span>
          <span>
            <i className="fa fa-female m-r-10"></i>1,00,215
          </span>
        </h5> */}

        <div id="m_area_chart" style={{ height: 300 }}></div>
      </div>
    </div>
  );
};

export default PaperOverviewGrapgh;
