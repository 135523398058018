import React, { useState, useEffect } from "react";

// components
import CustomPagination from "components/CustomPagination ";

// packages
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";

// hooks
import { useGetQuestions } from "hooks/api/subject";
import { useTable } from "hooks/custom/useTable";

const QuestionsModal = ({
  type,
  board_id,
  class_id,
  subject_id,
  selectedChapters,
  selectedQuestions,
  setSelectedQuestions,
}) => {
  const [modal, setModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { data: questions } = useGetQuestions();

  useEffect(() => {
    if (questions?.data) {
      const data = filterArray(
        { board_id, class_id, subject_id, type },
        questions?.data
      );

      setRows(
        data.filter((ele) =>
          selectedChapters?.length > 0
            ? selectedChapters.includes(ele.chapter_id) && ele.status == 1
            : ele.status == 1
        )
      );
    }
  }, [
    questions?.data,
    board_id,
    class_id,
    subject_id,
    JSON.stringify(selectedChapters),
  ]);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const handleClose = () => setModal(false);

  return (
    <>
      <button
        className="btn btn-primary ml-auto"
        onClick={() => setModal(true)}
      >
        Add Questions
      </button>

      <Modal size="xl" centered show={modal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Select Questions</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="body table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Question</th>
                  <th>CHAPTER</th>
                  <th>TOPIC</th>
                </tr>
              </thead>
              <tbody>
                {rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <tr key={row.id}>
                      <td style={{ width: "60px" }}>
                        <div className="form-group">
                          <label className="fancy-checkbox">
                            <input
                              name="checkbox"
                              type="checkbox"
                              checked={
                                selectedQuestions.filter(
                                  (ele) => ele?.id === row?.id
                                ).length > 0
                                  ? true
                                  : false
                              }
                              onChange={(value) => {
                                const exist = selectedQuestions.filter(
                                  (ele) => ele.id === row.id
                                );

                                if (exist.length > 0) {
                                  setSelectedQuestions(
                                    selectedQuestions.filter(
                                      (ele) => ele.id !== row.id
                                    )
                                  );
                                } else {
                                  setSelectedQuestions([
                                    ...selectedQuestions,
                                    row,
                                  ]);
                                }
                              }}
                            />
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>{parse(`${row.question}`)}</td>
                      <td>{row.chapter_name}</td>
                      <td>{row.topic_name}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
            <CustomPagination
              count={rows.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuestionsModal;

const removeNullValues = (obj) => {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
  return obj;
};

const filterArray = (filterObj, data) => {
  console.log("data: filter", data);
  console.log("filterObj filter: ", filterObj);
  const obj = removeNullValues({ ...filterObj });

  const filteredArray = data.filter((item) => {
    let match = true;
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (item[key] != obj[key]) {
          match = false;
          break;
        }
      }
    }
    return match;
  });

  return filteredArray;
};
