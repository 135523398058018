import React from "react";

// third party
import { Controller } from "react-hook-form";

export default function FormTextField({ control, error, item }) {
  return (
    <Controller
      control={control}
      name={item.name}
      defaultValue={item?.defaultValue || ""}
      rules={item.rules}
      render={({ field }) => (
        <div className="col-6 mb-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">{item.label}</span>
            </div>
            <input
              {...field}
              type={item?.type || "text"}
              placeholder={item.placeholder}
              disabled={!!item?.disabled}
              className="form-control"
            />
          </div>
          {error?.message && (
            <ul className="parsley-errors-list filled">
              <li className="parsley-required">{error?.message}</li>
            </ul>
          )}
        </div>
      )}
    />
  );
}
