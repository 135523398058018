import React, { useState } from "react";
import PageHeader from "components/BreadCrumb";

// apis
import { complaint } from "api/v1/feedback";

// hook
import { useToaster } from "hooks/custom/useToaster";

const Feedback = () => {
  return (
    <div className="col-lg-8 col-md-12">
      <PageHeader Breadcrumb={[{ name: "Feedback Complaint" }]} />
      <div className="card">
        <div className="body">
          <ul className="nav nav-tabs" role="tablist">
            <li className={`nav-item mr-1 active`} role="presentation">
              <a className="nav-link" data-toggle="tab">
                Lodge a Complaint
              </a>
            </li>
          </ul>
          <div className="tab-content" style={{ padding: "40px 20px" }}>
            <LodgeComplaint />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

const LodgeComplaint = () => {
  const [textInput, setTextInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [category, setCategory] = useState("");
  const [areaInput, setAreaInput] = useState("");
  const [submeet, setSubmeet] = useState(false);
  const { toasterError, toasterSuccess } = useToaster();

  const createComplaint = async () => {
    if (!textInput || !emailInput || !areaInput || !category) {
      return;
    }

    try {
      await complaint({
        name: textInput,
        email: emailInput,
        description: areaInput,
        category,
      });
      toasterSuccess(`Complaint registered`);
    } catch (err) {
      toasterError(`${err.errMsg}`);
    }
  };
  return (
    <div id="bacicTabpan-2" className={`tab-pane show active`}>
      <h6>
        You can now easily lodge a complaint if you face any ambiguity
        navigating the system. 
      </h6>
      <p>Please fill in the form below:</p>
      <form className="ng-untouched ng-dirty ng-invalid">
        <div className="form-group">
          <label>Your Name</label>
          <input
            type="text"
            className={`form-control ${
              areaInput === "" && submeet && "parsley-error"
            }`}
            value={areaInput}
            onChange={(e) => {
              setAreaInput(e.target.value);
            }}
          />
          {areaInput === "" && submeet ? (
            <ul className="parsley-errors-list filled" id="parsley-id-29">
              <li className="parsley-required">This value is required.</li>
            </ul>
          ) : null}
        </div>
        <div className="form-group">
          <label>Your Email</label>
          <input
            type="email"
            className={`form-control ${
              areaInput === "" && submeet && "parsley-error"
            }`}
            value={emailInput}
            onChange={(e) => {
              setEmailInput(e.target.value);
            }}
          />
          {emailInput === "" && submeet ? (
            <ul className="parsley-errors-list filled" id="parsley-id-29">
              <li className="parsley-required">This value is required.</li>
            </ul>
          ) : null}
        </div>
        <div className="form-group">
          <label>Please select the category of issue below:</label>
          <br />
          <label className="fancy-checkbox">
            <input
              name="checkbox"
              type="checkbox"
              defaultChecked={category == "Technical issues" ? true : false}
              checked={category == "Technical issues" ? true : false}
              onChange={() => setCategory("Technical issues")}
            />
            <span>Technical issues</span>
          </label>
          <label className="fancy-checkbox">
            <input
              name="checkbox"
              type="checkbox"
              defaultChecked={category == "Academic Errors" ? true : false}
              checked={category == "Academic Errors" ? true : false}
              onChange={() => setCategory("Academic Errors")}
            />
            <span>Academic Errors</span>
          </label>
          <label className="fancy-checkbox">
            <input
              name="checkbox"
              type="checkbox"
              defaultChecked={category == "Others" ? true : false}
              checked={category == "Others" ? true : false}
              onChange={() => setCategory("Others")}
            />
            <span>Others</span>
          </label>
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea
            className={`form-control ${
              areaInput === "" && submeet && "parsley-error"
            }`}
            value={textInput}
            onChange={(e) => {
              setTextInput(e.target.value);
            }}
          />
          {textInput === "" && submeet ? (
            <ul className="parsley-errors-list filled" id="parsley-id-29">
              <li className="parsley-required">This value is required.</li>
            </ul>
          ) : null}
        </div>
        <br />
      </form>
      <button
        className="btn btn-primary"
        onClick={() => {
          setSubmeet(true);
          createComplaint();
        }}
      >
        Submit
      </button>
    </div>
  );
};
