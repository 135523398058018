import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import CustomPagination from "components/CustomPagination ";

// hooks
import { useTable } from "hooks/custom/useTable";
import { useDeleteCampusUser, useGetCampusUsers } from "hooks/api/campusUser";
import { useGetCampuses } from "hooks/api/campus";
import { useGetRoles } from "hooks/api/admin";

const Campus = () => {
  const history = useHistory();

  const [rows, setRows] = useState([]);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const { data: campusUsers, refetch } = useGetCampusUsers();
  const deleteCampusUser = useDeleteCampusUser(refetch);
  const { data: roles } = useGetRoles();
  const { data: campuses } = useGetCampuses();

  useEffect(() => {
    if (campusUsers?.data && roles?.data && campuses?.data) {
      const mappedRows = campusUsers.data.map((user) => {
        const roleName = roles?.data.find(
          (role) => role.id === user.roleid
        )?.name;
        const campusName = campuses?.data.find(
          (campus) => campus.id === user.user_campus_id
        )?.name;
        return { ...user, roleName, campusName };
      });
      setRows(mappedRows);
    }
  }, [campusUsers?.data, roles?.data, campuses?.data]);

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Campuses"
        Breadcrumb={[{ name: "Campus Users" }]}
      />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>All Users</h2>

          <Link to="/campus-users/create">
            <button
              type="button"
              className="btn btn-success"
              title="Create Campus User"
            >
              <span className="sr-only">Create New User</span>
              <i className="fa fa-plus mr-1"></i> <span>Create New User</span>
            </button>
          </Link>
        </div>

        <div className="body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Campus</th>
                <th style={{ textAlign: "end" }}>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <tr key={row.id}>
                    <td>{row.name}</td>
                    <th scope="row">{row.email}</th>
                    <td>{row.roleName || "N/A"}</td>
                    <td>{row.campusName || "N/A"}</td>
                    <td style={{ display: "flex", justifyContent: "flex-end" }}>
                      <button
                        type="button"
                        className="btn btn-danger"
                        title="Delete"
                        onClick={() => deleteCampusUser.mutate(row.id)}
                      >
                        <span className="sr-only">Delete</span>
                        <i className="fa fa-trash-o"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mr-1"
                        title="Edit"
                        onClick={() =>
                          history.push(`/campus-users/edit/${row.id}`)
                        }
                      >
                        <span className="sr-only">Edit</span>
                        <i className="fa fa-edit"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Campus;
