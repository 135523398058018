import React from "react";

// components
import FormTextField from "components/FormField/TextField";

// packages
import { useForm } from "react-hook-form";
import _ from "lodash";

// api's hook
import { useToaster } from "hooks/custom/useToaster";
import { changePassword } from "api/v1/auth";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const ChangePassword = () => {
  const { toasterError, toasterSuccess } = useToaster();
  const { handleSubmit, control, formState } = useForm();

  async function onSubmit(data) {
    try {
      if (data.newPass !== data.confirmPass) {
        toasterError(`New and confirm password is not same`);
        return;
      }
      await changePassword(data);
      toasterSuccess("Password Change");
    } catch (err) {
      toasterError(`${err.errMsg}`);
    }
  }

  return (
    <div className="container-fluid">
      <div className="mt-4 card">
        <div className="header">
          <h2>Change Password</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormTextField
                control={control}
                error={formState?.errors?.oldPass}
                item={{
                  name: "oldPass",
                  label: "Old Password",
                  placeholder: "Enter Old Password",
                  rules,
                }}
              />
              <div className="col-6 mb-4" />
              <FormTextField
                control={control}
                error={formState?.errors?.newPass}
                item={{
                  name: "newPass",
                  label: "New Password",
                  placeholder: "Enter New Password",
                  rules,
                }}
              />
              <div className="col-6 mb-4" />
              <FormTextField
                control={control}
                error={formState?.errors?.confirmPass}
                item={{
                  name: "confirmPass",
                  label: "Confirm Password",
                  placeholder: "Enter Confirm Password",
                  rules,
                }}
              />
            </div>
            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
