import React from "react";

const EntryFeedBackField = ({ state, setState, onSave }) => {
  return (
    <>
      <div className="body">
        <div className="row clearfix">
          <div className="col-6 mb-2">
            <div className="input-group">
              <div className="input-group-prepend">
                <label className="input-group-text">Status:</label>
              </div>
              <select
                className="custom-select"
                value={state?.status || "Resolved"}
                onChange={(e) =>
                  setState({ ...state, ["status"]: e.target.value })
                }
              >
                {[
                  { value: 0, label: "Deactivate" },
                  { value: 1, label: "Active" },
                  { value: 2, label: "Feedback" },
                  { value: 3, label: "Resolved" },
                ]?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-12">
            <div className="input-group-prepend">
              <span className="input-group-text">FeedBack:</span>
            </div>
            <textarea
              placeholder={"Write"}
              className={`form-control custom-text-area-height`}
              value={state?.feedback}
              onChange={(e) =>
                setState({ ...state, ["feedback"]: e.target.value })
              }
            />
          </div>
        </div>
      </div>

      <div className="body">
        <button
          type="submit"
          className="btn btn-primary ml-auto"
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default EntryFeedBackField;
