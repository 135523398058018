import React from "react";

// packages
import parse from "html-react-parser";

// components
import PunjabPaperFormat from "./Punjab";
import SindhPaperFormat from "./Sindh";
import SindhUrduFormat from "./SindhUrdu";
import SindhIslamiyatFormat from "./Islamiyat";
import SindhSindhiFormat from "./SindhSindhi";
import PunjabTadreesQuran from "./PunjabTadreesQuran";
import KpkPaperFormat from "./KPK";

const PaperFormat = ({ data, showMcqAnswer }) => {
  const isPunjab = data?.paper_details[0].board_type == "Punjab";
  const subjectCheck = data?.paper_details[0]?.subject_id;
  const boardType = data?.paper_details[0].paper_board_type_id;
  return (
    <div>
      {boardType === 2 && subjectCheck !== 17 && subjectCheck !== 20 ? (
        <PunjabPaperFormat data={data} showMcqAnswer={showMcqAnswer} />
      ) : (
        <>
          {boardType === 1 ? (
            <KpkPaperFormat data={data} showMcqAnswer={showMcqAnswer} />
          ) : (
            <>
              {boardType === 4 && subjectCheck === 17 ? (
                <SindhUrduFormat data={data} showMcqAnswer={showMcqAnswer} />
              ) :boardType === 4 && subjectCheck === 4? (
                <SindhSindhiFormat data={data} showMcqAnswer={showMcqAnswer} />
              ) : boardType === 2 && subjectCheck === 17 ? (
                <SindhUrduFormat data={data} showMcqAnswer={showMcqAnswer} />
              ) : boardType === 4 && subjectCheck === 19 ? (
                <SindhIslamiyatFormat
                  data={data}
                  showMcqAnswer={showMcqAnswer}
                />
              ) : boardType === 2 && subjectCheck === 20 ? (
                <PunjabTadreesQuran data={data} showMcqAnswer={showMcqAnswer} />
              ) : (
                <SindhPaperFormat data={data} showMcqAnswer={showMcqAnswer} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PaperFormat;

// const MCQSCol = ({ col, number, text }) => {
//   return (
//     <p
//       className={col}
//       style={{ display: "flex", alignItems: "flex-start", margin: 0 }}
//     >
//       <span style={{ marginRight: "5px", fontWeight: "500" }}>{number}</span>
//       <span>{text}</span>
//     </p>
//   );
// };

// const MCQSFormatting = ({ data }) => {
//   return (
//     <div className="body">
//       {data?.paperMcqs?.map((ele, index) => (
//         <div
//           key={ele.id}
//           className="row clearfix"
//           style={{ marginBottom: "20px" }}
//         >
//           <MCQSCol
//             col="col-12"
//             number={`${index + 1}:`}
//             text={parse(`${ele.description}`)}
//           />
//           <MCQSCol col="col-3" number={"a:"} text={parse(`${ele.opt_a}`)} />
//           <MCQSCol col="col-3" number={"b:"} text={parse(`${ele.opt_b}`)} />
//           <MCQSCol col="col-3" number={"c:"} text={parse(`${ele.opt_c}`)} />
//           <MCQSCol col="col-3" number={"d:"} text={parse(`${ele.opt_d}`)} />
//         </div>
//       ))}
//     </div>
//   );
// };

// const EnglishMCQSFormatting = ({ data }) => {
//   const mcqs = React.useMemo(() => {
//     let mcqsData = [...data.paperMcqs];

//     // Object to store grouped MCQs
//     const groupedMcqs = {};

//     // Group MCQs with the same description
//     for (const mcq of mcqsData) {
//       const description = mcq.description.replace(/ {2,}/g, " ");

//       if (description in groupedMcqs) {
//         groupedMcqs[description].children.push(mcq);
//       } else {
//         groupedMcqs[description] = {
//           mcq: description,
//           children: [mcq],
//         };
//       }
//     }

//     return groupedMcqs;
//   }, [data]);

//   return (
//     <div className="body">
//       {Object.values(mcqs || {})?.map((ele, index) => (
//         <div
//           key={index}
//           className="row clearfix"
//           style={{ marginBottom: "20px" }}
//         >
//           <MCQSCol
//             col="col-12"
//             number={`${index + 1}:`}
//             text={parse(`${ele.mcq}`)}
//           />

//           <div className="body" style={{ padding: "0 20px" }}>
//             {ele?.children?.map((mcqOpt) => (
//               <div
//                 key={`${index}-${mcqOpt.id}`}
//                 className="row"
//                 style={{ marginBottom: "10px" }}
//               >
//                 <MCQSCol
//                   col="col-3"
//                   number={"a:"}
//                   text={parse(`${mcqOpt.opt_a}`)}
//                 />
//                 <MCQSCol
//                   col="col-3"
//                   number={"b:"}
//                   text={parse(`${mcqOpt.opt_b}`)}
//                 />
//                 <MCQSCol
//                   col="col-3"
//                   number={"c:"}
//                   text={parse(`${mcqOpt.opt_c}`)}
//                 />
//                 <MCQSCol
//                   col="col-3"
//                   number={"d:"}
//                   text={parse(`${mcqOpt.opt_d}`)}
//                 />
//               </div>
//             ))}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// const QuestionsFormatting = ({ title, questions }) => {
//   return (
//     <>
//       <div className="header">
//         <h5>{title}</h5>
//         <small></small>
//       </div>
//       <div className="body">
//         {questions?.map((ele, index) => (
//           <div key={ele.id} className="row clearfix">
//             <p
//               className="col-12"
//               style={{ display: "flex", alignItems: "flex-start", margin: 0 }}
//             >
//               <span style={{ marginRight: "5px", fontWeight: "500" }}>
//                 Q{index + 1}:
//               </span>
//               <span>{parse(`${ele.question}`)}</span>
//             </p>
//           </div>
//         ))}
//       </div>
//     </>
//   );
// };
