import React from "react";
import { useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";

// packages
import { useForm } from "react-hook-form";
import _ from "lodash";

// api's hook
import {
  useCreateCampus,
  useGetCampus,
  useUpdateCampus,
} from "hooks/api/campus";
import { useGetRegions, useGetSchools } from "hooks/api/shared";
import { useGetBoards } from "hooks/api/subject";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateCampus = () => {
  const { id } = useParams();

  const { data: campus } = useGetCampus(id);

  const { data: schools } = useGetSchools();
  const { data: regions } = useGetRegions();
  const { data: boards } = useGetBoards();

  const createCampus = useCreateCampus();
  const updateCampus = useUpdateCampus();

  const { handleSubmit, reset, control, formState, setValue } = useForm();

  async function onSubmit(data) {
    try {
      const body = { ...data };

      id
        ? await updateCampus.mutateAsync({ id, body })
        : await createCampus.mutateAsync(body);

      reset();
    } catch (err) {}
  }

  React.useEffect(() => {
    if (campus?.data?.[0]) {
      let values = _.pick(campus?.data?.[0], [
        "name",
        "email",
        "password",
        "roleid",
      ]);

      values = { ...values, role: values.roleid };

      Object.entries(values).forEach(([key, val]) => {
        setValue(`${key}`, val);
      });
    }
  }, [campus]);

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create Campus"
        Breadcrumb={[
          { name: "Campuses", navigate: "/campuses" },
          { name: id ? "Edit" : "Create" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>{id ? "Edit" : "Create"} Campus</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormSelect
                control={control}
                error={formState?.errors?.user_group_id}
                item={{
                  name: "user_group_id",
                  label: "Schools",
                  options: schools?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.code}
                item={{
                  name: "code",
                  label: "Campus Code",
                  placeholder: "Enter campus code",
                  rules,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.name}
                item={{
                  name: "name",
                  label: "Campus Name",
                  placeholder: "Enter campus name",
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.region_id}
                item={{
                  name: "region_id",
                  label: "Campus Region",
                  options: regions?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.boardId}
                item={{
                  name: "board_id",
                  label: "Board",
                  options: boards?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
            </div>
            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              {id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCampus;
