import React from "react";

const Tab = ({ tabName, menuTab, setMenuTab }) => {
  return (
    <li className="nav-item" style={{ cursor: "pointer" }}>
      <a
        data-toggle="tab"
        className={menuTab ? "nav-link active" : "nav-link"}
        onClick={setMenuTab}
      >
        {tabName}
      </a>
    </li>
  );
};

const MenuTab = ({ menuTab, setMenuTab }) => {
  return (
    <ul className="nav nav-tabs">
      <Tab
        tabName={"Menu"}
        menuTab={menuTab === 0}
        setMenuTab={() => setMenuTab(0)}
      />

      <Tab
        tabName={<i className="icon-settings"></i>}
        menuTab={menuTab === 1}
        setMenuTab={() => setMenuTab(1)}
      />
    </ul>
  );
};

export default MenuTab;
