import client from "../api-config";

export function createCampusUser(body) {
  return client.post("/api/campususer/add", body);
}

export function getCampusUsers() {
  return client.get("/api/campususers");
}

export function getCampusUser(id) {
  return client.get(`/api/campususers/${id}`);
}

export function updateCampusUser(data) {
  return client.put(`/api/campususers/${data.id}`, { ...data.body });
}

export function deleteCampusUser(id) {
  return client.delete(`/api/campususers/${id}`);
}
