import React from "react";

// packages
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-classic-with-mathtype";

export default function CKTextEditor({ onChange = () => {}, value = "" }) {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={modules}
      data={value || ""}
      onChange={(event, editor) => {
        const data = editor.getData();
        // console.log({ event, editor, data });
        onChange(data);
      }}
    />
  );
}

const modules = {
  toolbar: {
    items: [
      // "heading",
      "MathType",
      "ChemType",
      "|",
      "bold",
      "italic",
      "bulletedList",
      "numberedList",
      // "insertTable",
      "undo",
      "redo",
    ],
  },
};

// const modules = {
//   toolbar: {
//     items: [
//       "heading",
//       "MathType",
//       "ChemType",
//       "|",
//       "bold",
//       "italic",
//       "link",
//       "bulletedList",
//       "numberedList",
//       "imageUpload",
//       "mediaEmbed",
//       "insertTable",
//       "blockQuote",
//       "undo",
//       "redo",
//     ],
//   },
// };

{
  /* <CKEditor
          editor={ClassicEditor}
          data="<p>Hello from CKEditor 5!</p>"
          config={{
            toolbar: {
              items: [
                "heading",
                "MathType",
                "ChemType",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                "imageUpload",
                "mediaEmbed",
                "insertTable",
                "blockQuote",
                "undo",
                "redo",
              ],
            },
          }}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        /> */
}
