export const formatDate = (inputDate) => {
  if (!inputDate) return null;
  const hasTime = /\d{2}:\d{2}/.test(inputDate);
  const options = hasTime
    ? {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }
    : { year: "numeric", month: "2-digit", day: "2-digit" };

  return new Date(inputDate).toLocaleString("en-US", options);
};

export function intToRoman(num) {
  const val = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  const syb = [
    "M",
    "CM",
    "D",
    "CD",
    "C",
    "XC",
    "L",
    "XL",
    "X",
    "IX",
    "V",
    "IV",
    "I",
  ];
  let romanNum = "";
  for (let i = 0; i < val.length; i++) {
    while (num >= val[i]) {
      romanNum += syb[i];
      num -= val[i];
    }
  }
  return romanNum;
}

export function numberToAlphabet(n) {
  const base = 26;
  let result = "";
  n -= 1;
  while (n >= 0) {
    let currentLetter = String.fromCharCode((n % base) + 97);
    result = currentLetter + result;
    n = Math.floor(n / base) - 1;
  }
  return `${result}`;
}


export function numberToArabicNumerals(input) {
  // Mapping of Western Arabic digits to Eastern Arabic digits
  const arabicNumerals = {
      '0': '٠',
      '1': '١',
      '2': '٢',
      '3': '٣',
      '4': '٤',
      '5': '٥',
      '6': '٦',
      '7': '٧',
      '8': '٨',
      '9': '٩'
  };

  // Convert input to a string and replace each digit with its Arabic counterpart
  return input.toString().replace(/\d/g, digit => arabicNumerals[digit]);
}
export function numberToAlifNumerals(input) {
  // Mapping of Western Arabic digits to Eastern Arabic digits
  const arabicNumerals = { 
      '1': 'الف',
      '2': 'ب',
      '3': 'ج',
      '4': 'د', 
  };

  // Convert input to a string and replace each digit with its Arabic counterpart
  return input.toString().replace(/\d/g, digit => arabicNumerals[digit]);
}
