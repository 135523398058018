import React from "react";

// packages
import parse from "html-react-parser";

// util
import { convertToRoman } from "utils/convertToRoman";
import { numberToAlifNumerals, numberToArabicNumerals } from "utils/Helper";
import { IMAGE_URL } from "constant";

export const UrduLongQuestions = ({
  data,
  subject_id,
  startIndex,
  class_id,
  isMcqExist,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  let i = 0;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => {
        const updatedIndex = ele?.ui_style == "یا" ? i : i++;
        return (
          <div key={index} className="mb-3">
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        updatedIndex + startIndex + startingQuestionNumber
                      )}
                      ۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "bullet_number" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        updatedIndex + startIndex + startingQuestionNumber
                      )}
                      ۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${numberToArabicNumerals(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}

            {(ele.ui_style == "" || ele.ui_style == "یا") && (
              <>
                {ele?.text != "" ? (
                  <div
                    className={`row text-right clearfix mb-2 font-weight-bold`}
                  >
                    {ele?.ui_style == "یا" ? (
                      <div className="col-12 text-center">
                        <span>۔۔۔۔۔۔یا۔۔۔۔۔</span>
                      </div>
                    ) : (
                      <div className="col-12 text-right">
                        <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            updatedIndex + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                        </span>
                        <span>{ele?.text}</span>
                      </div>
                    )}

                    <div className="col-10 d-flex">
                      {ele?.question_ids?.map((ques, index) => {
                        return (
                          <>
                            <span>{parse(`${ques.question}`)}</span>
                          </>
                        );
                      })}
                    </div>
                    <div className="col-2 text-right">{ele.marks}</div>
                  </div>
                ) : (
                  <div
                    className={`row text-right clearfix mb-2 font-weight-bold`}
                  >
                    {ele?.ui_style == "یا" ? (
                      <div className="col-12 text-center">
                        <span>۔۔۔۔۔۔یا۔۔۔۔۔</span>
                      </div>
                    ) : (
                      <div className="col-1 text-right">
                        <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            updatedIndex + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                        </span>
                      </div>
                    )}

                    <div className="col-10 d-flex text-right">
                      {ele?.question_ids?.map((ques, index) => {
                        return (
                          <>
                            <span>{parse(`${ques.question}`)}</span>
                          </>
                        );
                      })}
                    </div>
                    <div className="col-1 text-right">{ele.marks}</div>
                  </div>
                )}
              </>
            )}
            {ele.ui_style == "bullet" && (
              <>
                <div className={`row text-right clearfix mb-2 `}>
                  <div className="col-10 d-flex">
                    <span className="font-weight-bold">
                      سوال نمبر
                      {numberToArabicNumerals(
                        updatedIndex + startIndex + startingQuestionNumber
                      )}
                      ۔{" "}
                    </span>
                    <span>
                      {ele?.question_ids?.map((ques, index) => {
                        return (
                          <>
                            <p
                              className="col-12"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                margin: 0,
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${numberToAlifNumerals(index + 1)})`}
                              </span>
                              <span>{parse(`${ques.question}`)}</span>
                            </p>
                          </>
                        );
                      })}
                    </span>
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export const PunjabUrduLongQuestions = ({
  data,
  is10,
  subject_id,
  startIndex,
  class_id,
  isMcqExist,
}) => {
  const startingQuestionNumber = 1;
  let i = 0;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => {
        const updatedIndex = ele?.ui_style == "یا" ? i : i++;
        return (
          <div key={index} className="mb-3">
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      {!is10 && "سوال نمبر"}
                      {updatedIndex + startIndex + startingQuestionNumber}۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}

            {ele.ui_style == "bullet_romen_line" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      {!is10 && "سوال نمبر"}
                      {updatedIndex + startIndex + startingQuestionNumber}۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                <div className="row">
                  {ele?.question_ids?.map((ques, index) => {
                    const isEvenIndex = index % 2 === 0; // Check if the index is even

                    return (
                      <div
                        key={index} // Always include a key when rendering a list of elements
                        className={`col-6`}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          textAlign: isEvenIndex ? "left" : " right",
                          margin: 0,
                          padding: "0 10px", // Add some padding for visual separation
                        }}
                      >
                        <span
                          style={{
                            marginRight: isEvenIndex ? "0" : "5px",
                            marginLeft: isEvenIndex ? "5px" : "0",
                            fontWeight: "bold",
                          }}
                        >
                          {is10
                            ? `(${numberToAlifNumerals(index + 1)})`
                            : `${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {ele.ui_style == "bullet_romen_3line" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      {!is10 && "سوال نمبر"}
                      {updatedIndex + startIndex + startingQuestionNumber}۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                <div className="row">
                  {ele?.question_ids?.map((ques, index) => {
                    const isEvenIndex = index % 2 === 0; // Check if the index is even

                    return (
                      <div
                        key={index} // Always include a key when rendering a list of elements
                        className={`col-4`}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          textAlign: isEvenIndex ? "left" : " right",
                          margin: 0,
                          padding: "0 10px", // Add some padding for visual separation
                        }}
                      >
                        <span
                          style={{
                            marginRight: isEvenIndex ? "0" : "5px",
                            marginLeft: isEvenIndex ? "5px" : "0",
                            fontWeight: "bold",
                          }}
                        >
                          {is10
                            ? `(${numberToArabicNumerals(index + 1)})`
                            : `${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {(ele.ui_style == "" || ele.ui_style == "یا") && (
              <>
                {ele?.text != "" ? (
                  <div
                    className={`row text-right clearfix mb-2 font-weight-bold`}
                  >
                    {ele?.ui_style == "یا" ? (
                      <div className="col-12 text-center">
                        <span>۔۔۔۔۔۔یا۔۔۔۔۔</span>
                      </div>
                    ) : (
                      <div className="col-12 text-right">
                        <span>
                          {!is10 && "سوال نمبر"}
                          {updatedIndex + startIndex + startingQuestionNumber}۔
                        </span>
                        {ele?.text}
                      </div>
                    )}

                    <div className="col-10 d-flex">
                      {ele?.question_ids?.map((ques, index) => {
                        return (
                          <>
                            <span>{parse(`${ques.question}`)}</span>
                          </>
                        );
                      })}
                    </div>
                    <div className="col-2 text-right">{ele.marks}</div>
                  </div>
                ) : (
                  <div
                    className={`row text-right clearfix mb-2 font-weight-bold`}
                  >
                    {ele?.ui_style == "یا" ? (
                      <>
                        <div className="col-12 text-center">
                          <span>۔۔۔۔۔۔یا۔۔۔۔۔</span>
                        </div>
                        <div className="col-10 d-flex text-right">
                          {ele?.question_ids?.map((ques, index) => {
                            return (
                              <>
                                <span>{parse(`${ques.question}`)}</span>
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-10 text-right d-flex">
                          سوال نمبر
                          {updatedIndex + startIndex + startingQuestionNumber}۔
                          {ele?.question_ids?.map((ques, index) => {
                            return (
                              <>
                                <span>{parse(`${ques.question}`)}</span>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                    <div className="col-1 text-right">{ele.marks}</div>
                  </div>
                )}
              </>
            )}
            {ele.ui_style == "bullet" && (
              <>
                <div className={`row text-right clearfix mb-2 `}>
                  <div className="col-10 d-flex">
                    <span className="font-weight-bold">
                      {!is10 && "سوال نمبر"}
                      {updatedIndex + startIndex + startingQuestionNumber}۔
                      <span>{ele?.text}</span>۔{" "}
                    </span>
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                  <div className="col-12">
                    <span>
                      {ele?.question_ids?.map((ques, index) => {
                        return (
                          <>
                            <p
                              className="col-12"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                margin: 0,
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${numberToAlifNumerals(index + 1)})`}
                              </span>
                              <span>{parse(`${ques.question}`)}</span>
                            </p>
                          </>
                        );
                      })}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export const UrduShortQuestions = ({
  data,
  subject_id,
  class_id,
  isMcqExist,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;

  return (
    <div>
      <div className={`row clearfix mb-2 font-weight-bold`}>
        <div className="col-12 text-right">
          <span>ہدایات: {data?.text}</span>
        </div>
      </div>
      {data?.paperShortQuestions?.map((ele, index) => {
        return (
          <div className="mb-3">
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "bullet_romen_line" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10 d-flex">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "}
                    </span>
                    {ele?.sq_question_ids?.map((ques, index) => {
                      return (
                        <>
                          <span>{parse(`${ques.question}`)}</span>
                        </>
                      );
                    })}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>
              </>
            )}
            {ele.ui_style == "" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10 d-flex">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "}
                    </span>
                    {ele?.sq_question_ids?.map((ques, index) => {
                      return (
                        <>
                          <span>{parse(`${ques.question}`)}</span>
                        </>
                      );
                    })}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>
              </>
            )}
            {ele.ui_style == "bullet" && (
              <>
                <div className={`row text-right clearfix mb-2 `}>
                  <div className="col-10 d-flex">
                    <span className="font-weight-bold">
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "}
                    </span>
                    <span>
                      {ele?.sq_question_ids?.map((ques, index) => {
                        return (
                          <>
                            <p
                              className="col-12"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                margin: 0,
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${numberToAlifNumerals(index + 1)})`}
                              </span>
                              <span>{parse(`${ques.question}`)}</span>
                            </p>
                          </>
                        );
                      })}
                    </span>
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export const PunjabUrduShortQuestions = ({
  data,
  subject_id,
  class_id,
  isMcqExist,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      <div className={`row clearfix mb-2 font-weight-bold`}>
        <div className="col-10 text-right">
          <span>
            {" "}
            {class_id != 2 && "سوال نمبر"}2۔ {data?.text}
          </span>
        </div>
        <div className="col-2">{data?.marks}</div>
      </div>
      {data?.paperShortQuestions?.map((ele, index) => {
        return (
          <div key={index} className="mb-3">
            {ele.ui_style == "bullet" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-1">{ele.text}:</div>
                  <div className="col-11 text-right">
                    {ele?.sq_question_ids?.map((ques, index) => {
                      return (
                        <>
                          <p
                            className="col-12"
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              margin: 0,
                            }}
                          >
                            <span
                              style={{
                                margin: "0 0 0 10px",
                                fontWeight: "bold",
                              }}
                            >
                              {`${index + 1}. `}
                            </span>
                            <span>{parse(`${ques.question}`)}</span>
                          </p>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">{ele.text}</div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
