import React, { useEffect, useState } from "react";

// components
import PageHeader from "components/BreadCrumb";
import CustomPagination from "components/CustomPagination ";
import AddRemarks from "components/Modal/AddRemarks";

// packages
import moment from "moment";

// context
import { useAuthContext } from "context/auth.context";

// hooks and apis
import { useTable } from "hooks/custom/useTable";
import {
  useGetComplaints,
  useUpdateComplaintsStatus,
} from "hooks/api/admin-feedback";

//
import { USER_ROLES } from "constant";
import { formatDate } from "utils/Helper";
import { CSVLink } from "react-csv";

const Complaint = () => {
  const { user } = useAuthContext();
  const isAdmin = USER_ROLES.admin == user?.rolename?.toLowerCase();

  const [rows, setRows] = useState([]); 
  const [loading, setLoading] = useState(true);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const { data: complaints, refetch } = useGetComplaints();
  const complaintResolved = useUpdateComplaintsStatus(refetch);

  const updateComplaint = async (id, data) => {
    await complaintResolved.mutateAsync({
      id,
      body: { status: 1, remarks: data?.remarks },
    });
  };

  const addRemarks = async (id, data) => {
    await complaintResolved.mutateAsync({
      id,
      body: { status: data?.status, remarks: data?.remarks },
    });
  };

  useEffect(() => {
    if (complaints?.data) {
      setRows(
        isAdmin
          ? complaints?.data
          : complaints?.data?.filter(({ created_by }) => created_by == user.id)
      );
      setLoading(false);
    }
  }, [complaints?.data]);

  return (
    <div className="container-fluid">
      <PageHeader Breadcrumb={[{ name: "Complaints" }]} />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>All Complaints</h2>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {rows && rows.length > 0 ? (
              <CSVLink filename={"all-complaints.csv"} data={rows}>
                {" "}
                <button type="button" className="btn btn-primary">
                  <span className="mx-1">Export Complaints</span>
                  <i className="fa fa-download"></i>
                </button>
              </CSVLink>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ minWidth: "200px" }}>NAME</th>
                  <th>Date & Time</th>
                  <th>EMAIL</th>
                  <th style={{ minWidth: "100px" }}>GROUP</th>
                  <th style={{ minWidth: "100px" }}>CAMPUS</th>
                  <th style={{ minWidth: "200px" }}>DESCRIPTION</th>
                  <th>Remarks</th>
                  <th>CATEGORY</th>
                  <th>CREATED</th>
                  <th>Status</th>
                  {isAdmin && <th style={{ textAlign: "end" }}>Action</th>}
                </tr>
              </thead>
              <tbody>
                {loading?
              <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td
                style={{
                  textAlign: "center",
                  height: "30vh",
                  width: "100%",
                }}
                className="visible-lg"
              >
                <i className="fa fa-spinner fa-4x fa-spin"></i>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>  : <> {rows?.length > 0 ? (
                  rows
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row) => (
                      <tr key={row.id}>
                        <th scope="row">{row.name}</th>
                        <td>{formatDate(row?.created_at)}</td>
                        <td>{row.email}</td>
                        <td>{row.user_group_name}</td>
                        <td>{row.campus_name}</td>
                        <td>{row?.description}</td>
                        <td>{row?.remarks}</td>
                        <td>{row?.category}</td>
                        <td>
                          {moment(new Date(row.created_at)).format(
                            "YYYY-MM-DD"
                          )}
                        </td>
                        <td>
                          <button
                            type="button"
                            className={`btn ${
                              !!row?.complaint_status
                                ? "btn-success"
                                : "btn-warning"
                            } mr-1`}
                            title="View"
                            onClick={() =>
                              !row?.complaint_status &&
                              updateComplaint(row.id, row)
                            }
                          >
                            <span>
                              {!!row?.complaint_status
                                ? "Resolved"
                                : "UnResolved"}
                            </span>
                          </button>
                        </td>
                        {isAdmin && (
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <AddRemarks
                                data={row}
                                addRemarks={(id, remarks) =>
                                  addRemarks(id, {
                                    status: !!row?.complaint_status ? 1 : 0,
                                    remarks,
                                  })
                                }
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "center",
                        height: "30vh",
                        fontSize: "1.5rem",
                        width: "100%",
                      }}
                    >
                      Oops! No data found
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}</>
              }
               
              </tbody>
            </table>
          </div>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Complaint;
