import React from "react";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

//
import { convertToRoman } from "utils/convertToRoman";
import { IMAGE_URL } from "constant";

const MCQSCol = ({ col, number, text, image, isRightOption }) => {
  const active = isRightOption && {
    backgroundColor: "lightgreen",
    border: "1px solid green",
    borderRadius: "1px",
    color: "#fff",
    padding: "0 2px",
  };
  return (
    <p
      className={col}
      style={{ display: "flex", alignItems: "flex-start", margin: 0 }}
    >
      <span style={{ marginRight: "5px", fontWeight: "500", ...active }}>
        {number}
      </span>
      <span>
        <span>{text}</span>

        {!!image && image != "" && (
          <span>
            <img
              src={`${IMAGE_URL}/mcqs/${image}`}
              alt="image"
              style={{ maxWidth: "100%" }}
            />
          </span>
        )}
      </span>
    </p>
  );
};

export const MCQSFormat = ({ subjectCheck, data, showMcqAnswer }) => {
  const isMath = ["5", "12"].includes(`${data?.paper_details[0]?.subject_id}`);

  return (
    <div className="body">
      {data?.paperMcqs?.map((ele, index) => (
        <div key={ele.id} className="row clearfix  mb-3">
          <MCQSCol
            subjectCheck={subjectCheck}
            col="col-12"
            number={
              subjectCheck === 18
                ? `${index + 1}.`
                : `(${convertToRoman(index + 1)})`
            }
            text={
              isMath ? (
                <MathJax math={ele.description} />
              ) : (
                parse(`${ele.description}`)
              )
            }
            image={ele.file}
          />
          <MCQSCol
            col="col-3"
            subjectCheck={subjectCheck}
            number={
              subjectCheck === 18 ? (
                <img src="https://img.icons8.com/?size=15&id=60003&format=png&color=000000" />
              ) : subjectCheck === 16 ? (
                <img src="https://img.icons8.com/?size=20&id=104&format=png&color=000000" />
              ) : (
                "a. "
              )
            }
            text={isMath ? <MathJax math={ele.opt_a} /> : parse(`${ele.opt_a}`)}
            image={ele.opt_a_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_a"}
          />
          <MCQSCol
            subjectCheck={subjectCheck}
            col="col-3"
            number={
              subjectCheck === 18 ? (
                <img src="https://img.icons8.com/?size=15&id=60003&format=png&color=000000" />
              ) : subjectCheck === 16 ? (
                <img src="https://img.icons8.com/?size=20&id=104&format=png&color=000000" />
              ) : (
                "b. "
              )
            }
            text={isMath ? <MathJax math={ele.opt_b} /> : parse(`${ele.opt_b}`)}
            image={ele.opt_b_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_b"}
          />
          <MCQSCol
            subjectCheck={subjectCheck}
            col="col-3"
            number={
              subjectCheck === 18 ? (
                <img src="https://img.icons8.com/?size=15&id=60003&format=png&color=000000" />
              ) : subjectCheck === 16 ? (
                <img src="https://img.icons8.com/?size=20&id=104&format=png&color=000000" />
              ) : (
                "c. "
              )
            }
            text={isMath ? <MathJax math={ele.opt_c} /> : parse(`${ele.opt_c}`)}
            image={ele.opt_c_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_c"}
          />
          <MCQSCol
            subjectCheck={subjectCheck}
            col="col-3"
            number={
              subjectCheck === 18 ? (
                <img src="https://img.icons8.com/?size=15&id=60003&format=png&color=000000" />
              ) : subjectCheck === 16 ? (
                <img src="https://img.icons8.com/?size=20&id=104&format=png&color=000000" />
              ) : (
                "d. "
              )
            }
            text={isMath ? <MathJax math={ele.opt_d} /> : parse(`${ele.opt_d}`)}
            image={ele.opt_d_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_d"}
          />
        </div>
      ))}
    </div>
  );
};
