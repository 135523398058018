import React from "react";
import { useHistory } from "react-router-dom";

// context
import { useAuthContext } from "context/auth.context";

// packages
import { Dropdown } from "react-bootstrap";

const UserProfile = () => {
  const history = useHistory();
  const { user, onLogout } = useAuthContext();

  const handleLogout = () => {
    onLogout();
    history.push("/login");
  };

  return (
    <div className="user-account">
      <img
        src={"https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000"}
        className="rounded-circle user-photo"
        alt="User Profile Picture"
      />
      <Dropdown>
        <span>Welcome,</span>
        <Dropdown.Toggle
          variant="none"
          as="a"
          id="dropdown-basic"
          className="user-name"
          style={{ cursor: "pointer" }}
        >
          <strong style={{ textTransform: "capitalize" }}>{user?.name}</strong>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-right account">
          {items({ handleLogout }).map((ele) => (
            <Dropdown.Item key={ele.name} onClick={ele.onClick}>
              <i className={ele.icon}></i>
              {ele.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default UserProfile;

const items = ({ handleLogout }) => [
  { name: "Settings", href: "settings", icon: "icon-settings" },
  {
    name: "Logout",
    href: "login",
    icon: "icon-power",
    onClick: handleLogout,
  },
];
