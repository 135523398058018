export const dataSchoolUser = "school";
export const dataEntryUser = "data entry";
export const dataReviewUser = "data review";

export const USER_ROLES = {
  super_admin: "super admin",
  admin: "admin",
  school: "school",
  teacher: "teacher",
  data_review: "data review",  
  data_entry: "data entry",
};

export const defaultPermissions = [
  "admin",
  "super admin",
  "school",
  "teacher",
  "data review",
  "data entry",
];

export const defaultAdminPermissions = ["admin", "super admin"];

export const defaultPaperGeneratePermissions = [
  "super admin",
  "admin",
  "school",
  "teacher",
];

export const defaultEntryPermissions = ["super admin", "admin", "data entry"];

export const defaultReviewPermissions = [
  "admin",
  "super admin",
  dataReviewUser,
];

export const IMAGE_URL = `${process.env.REACT_APP_API_BASE_URL}/uploads`;
