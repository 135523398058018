import React, { useEffect, useState } from "react";

// components
import PageHeader from "components/BreadCrumb";
import CustomPagination from "components/CustomPagination ";

// hooks
import { useTable } from "hooks/custom/useTable";
import { useGetRateReviews } from "hooks/api/admin-feedback";
import { formatDate } from "utils/Helper";
import { CSVLink } from "react-csv";

const RateRieview = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const { data: rateReviews } = useGetRateReviews();

  useEffect(() => {
    if (rateReviews?.data) {
      setRows(rateReviews?.data);
      setLoading(false);
    }
  }, [rateReviews?.data]);

  return (
    <div className="container-fluid">
      <PageHeader Breadcrumb={[{ name: "Rate Reviews" }]} />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>All Rate Reviews</h2>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {rows && rows.length > 0 ? (
              <CSVLink filename={"all-reviews.csv"} data={rows}>
                {" "}
                <button type="button" className="btn btn-primary">
                  <span className="mx-1">Export Reviews</span>
                  <i className="fa fa-download"></i>
                </button>
              </CSVLink>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>USER ID</th>
                  <th>Date & Time</th>
                  <th>USER NAME</th>
                  <th>REVIEWS</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "center",
                        height: "30vh",
                        width: "100%",
                      }}
                      className="visible-lg"
                    >
                      <i className="fa fa-spinner fa-4x fa-spin"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <>
                    {rows?.length > 0 ? (
                      rows
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row) => (
                          <tr key={row.id}>
                            <th scope="row">{row.user_id}</th>
                            <td>{formatDate(row?.created_at)}</td>
                            <td>{row.user_name}</td>
                            <td>{row?.rate_review}</td>
                            <td>
                              {row?.rate_review_stars >= 0 &&
                                row?.rate_review_stars <= 5 &&
                                [1, 2, 3, 4, 5].map((star) => (
                                  <span
                                    key={star}
                                    className={`fa ${
                                      star <= Number(row?.rate_review_stars)
                                        ? "fa-star"
                                        : "fa-star-o"
                                    }`}
                                    style={{
                                      color: "#ffa500",
                                      fontSize: "16px",
                                    }}
                                  ></span>
                                ))}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                            height: "30vh",
                            fontSize: "1.5rem",
                            width: "100%",
                          }}
                        >
                          Oops! No data found
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default RateRieview;
