import React, { useState } from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({ Breadcrumb }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const onToggleMenu = () => {
    if (toggleMenu) {
      document.body.classList.remove("layout-fullwidth");
    } else {
      document.body.classList.add("layout-fullwidth");
    }
    setToggleMenu((pre) => !pre);
  };

  return (
    <div className="block-header">
      <div className="row">
        <div className="col-sm-12">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <div
                className="btn btn-xs btn-link btn-toggle-fullwidth"
                onClick={onToggleMenu}
              >
                <i
                  className={
                    !toggleMenu ? `fa fa-arrow-left` : "fa fa-arrow-right"
                  }
                ></i>
              </div>
            </li>
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="icon-home"></i>
              </Link>
            </li>
            {Breadcrumb.map((item, index) => {
              return (
                <li key={item.name + index} className="breadcrumb-item active">
                  <Link
                    to={item.navigate ? item.navigate : null}
                    style={{ color: !item.navigate && "inherit" }}
                  >
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
