export const paperOverview = (data) => {
  const balochistanBoard = data[5] || Array(12).fill(0);
  const sindhBoard = data[4] || Array(12).fill(0);
  const federalBoard = data[3] || Array(12).fill(0);
  const punjabBoard = data[2] || Array(12).fill(0);
  const kpkBoard = data[1] || Array(12).fill(0);

  return {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      top: "4%",
      left: "1%",
      right: "1%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        axisLine: {
          show: false,
        },
        axisLabel: {
          textStyle: {
            color: "#a9a9a9",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            color: ["#E0E6F1"],
          },
        },

        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: function (value, index) {
            if (value > 0) {
              return value;
            } else {
              return 0;
            }
          },
          textStyle: {
            color: "#a9a9a9",
          },
        },
      },
    ],
    series: [
      {
        type: "line",
        name: "Balochistan Board",
        data: Object.values(balochistanBoard).map((ele) => ele.length),
        itemStyle: {
          color: "#f79647",
        },
        symbolSize: 8,
        smooth: true,
      },
      {
        type: "line",
        name: "Sindh Board",
        data: Object.values(sindhBoard).map((ele) => ele.length),
        itemStyle: {
          color: "#5ebc4f",
        },
        symbolSize: 8,
        smooth: true,
      },
      {
        type: "line",
        name: "Federal Board",
        data: Object.values(federalBoard).map((ele) => ele.length),
        itemStyle: {
          color: "#604a7b",
        },
        symbolSize: 8,
        smooth: true,
      },
      {
        type: "line",
        name: "Punjab Board",
        data: Object.values(punjabBoard).map((ele) => ele.length),
        itemStyle: {
          color: "#4f81bc",
        },
        symbolSize: 8,
        smooth: true,
      },
      {
        type: "line",
        name: "KPK Board",
        data: Object.values(kpkBoard).map((ele) => ele.length),
        itemStyle: {
          color: "#bc4fa8",
        },
        symbolSize: 8,
        smooth: true,
      },
    ],
  };
};
