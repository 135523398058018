// third party
import { useQuery, useMutation } from "@tanstack/react-query";

// hooks
import {
  getRoles,
  createUser,
  getUser,
  getUsers,
  updateUser,
  deleteUser,
} from "api/v1/admin";

// custom hook
import { useToaster } from "hooks/custom/useToaster";

export const useGetRoles = () => {
  return useQuery({
    queryKey: ["roles"],
    queryFn: () => getRoles(),
    refetchOnWindowFocus: false,
  });
};

// Users
export const useCreateUser = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createUser,
    onSuccess: () => toasterSuccess("User created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useGetUser = (id) => {
  return useQuery({
    queryKey: ["users", id],
    queryFn: () => getUser(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useGetUsers = () => {
  return useQuery({
    queryKey: ["users"],
    queryFn: () => getUsers(),
    refetchOnWindowFocus: false,
  });
};

export const useUpdateUser = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateUser,
    onSuccess: () => toasterSuccess("Updated!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useDeleteUser = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      refetch();
      toasterSuccess("User Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};
