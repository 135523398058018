import React, { useEffect, useState } from "react";

// api's hook
import { useGetClasses, useGetSubjects } from "hooks/api/subject";
import { useGetRegions } from "hooks/api/shared";
import { useGetPaperTypes } from "hooks/api/paper";
import { useGetCampuses } from "hooks/api/campus";

// context
import { useAuthContext } from "context/auth.context";
import { defaultAdminPermissions } from "constant";

const defaultValues = {
  region_id: 0,
  campus_id: 0,
  paper_type_id: 0,
  subjectId: 0,
  class_id: 0,
  fromDate: "",
  toDate: "",
};
const PaperReportsFilters = ({ submitReport }) => {
  const { user } = useAuthContext();

  const [state, setState] = useState(defaultValues);
  const [area, setArea] = useState("Nation Wide");

  const { data: regions } = useGetRegions();
  const { data: campuses } = useGetCampuses();
  const { data: subjects } = useGetSubjects();
  const { data: paperTypes } = useGetPaperTypes();
  const { data: classes } = useGetClasses();

  const handleSelectChange = (key, value) => {
    setState({ ...state, [key]: Number(value) });
  };

  const handleApply = () => {
    submitReport({ ...state });
  };

  useEffect(() => handleApply(), []);

  return (
    <div className="body">
      <div className="row clearfix">
        {defaultAdminPermissions.includes(user?.rolename?.toLowerCase()) && (
          <>
            <CustomSelect
              label="Area Wide"
              value={area || ""}
              onChange={(e) => setArea(e.target.value)}
              options={["Nation Wide", "Region Wide"].map((ele) => ({
                value: ele,
                label: ele,
              }))}
            />
            {area != "Nation Wide" && (
              <CustomSelect
                label="Regions"
                value={state?.region_id || ""}
                onChange={(e) =>
                  handleSelectChange("region_id", e.target.value)
                }
                options={regions?.data?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                }))}
              />
            )}
            <CustomSelect
              label="Campuses"
              value={state?.campus_id || ""}
              onChange={(e) => handleSelectChange("campus_id", e.target.value)}
              options={campuses?.data?.map((ele) => ({
                value: ele.id,
                label: ele.name,
              }))}
            />
          </>
        )}

        <CustomSelect
          label="Paper Types"
          value={state?.paper_type_id || ""}
          onChange={(e) => handleSelectChange("paper_type_id", e.target.value)}
          options={paperTypes?.data?.map((ele) => ({
            value: ele.id,
            label: ele.type_name,
          }))}
        />

        <CustomSelect
          label="Subjects"
          value={state?.subjectId || ""}
          onChange={(e) => handleSelectChange("subjectId", e.target.value)}
          options={subjects?.data?.map((ele) => ({
            value: ele.id,
            label: ele.name,
          }))}
        />

        <CustomSelect
          label="Classes"
          value={state?.class_id || ""}
          onChange={(e) => handleSelectChange("class_id", e.target.value)}
          options={classes?.data?.map((ele) => ({
            value: ele.id,
            label: ele.name,
          }))}
        />

        <CustomTextField
          label="From"
          type="date"
          value={state?.fromDate || ""}
          onChange={(e) => setState({ ...state, fromDate: e.target.value })}
        />

        <CustomTextField
          label="To"
          type="date"
          value={state?.toDate || ""}
          onChange={(e) => setState({ ...state, toDate: e.target.value })}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          type="button"
          className="btn btn-success"
          title="Clear All"
          onClick={handleApply}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default PaperReportsFilters;

const CustomSelect = ({ label, options, value, onChange }) => {
  return (
    <div className="col-4 mb-4">
      <div className="input-group">
        <div className="input-group-prepend">
          <label className="input-group-text">{label}</label>
        </div>
        <select className="custom-select" value={value} onChange={onChange}>
          <option value="">-- select an option --</option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const CustomTextField = ({ label, type, value, onChange }) => {
  return (
    <div className="col-4 mb-4">
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">{label}</span>
        </div>
        <input
          type={type}
          placeholder={""}
          value={value}
          onChange={onChange}
          className="form-control"
        />
      </div>
    </div>
  );
};
