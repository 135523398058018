import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

// packages
import { Nav } from "react-bootstrap";

// context
import { useAuthContext } from "context/auth.context";

// assets
import bssMcqResSheet from "../../assets/pdf/bss-mcq.pdf";
import eduMcqResSheet from "../../assets/pdf/edu-mcq.pdf";

//
import {
  USER_ROLES,
  dataSchoolUser,
  defaultAdminPermissions,
  defaultPaperGeneratePermissions,
} from "constant";

const MenuLink = ({ activeKey, href, title, onClick }) => {
  if (!href) {
    return (
      <li onClick={onClick}>
        <Link to={"#"}>{title}</Link>
      </li>
    );
  }

  return (
    <li className={`${activeKey}` === href ? "active" : ""}>
      <Link to={href}>{title}</Link>
    </li>
  );
};

const MenuList = ({ activeMenuTabContainer }) => {
  const { user } = useAuthContext();
  const location = useLocation();
  const activeKey = location.pathname;

  const isEduUser = user.user_group_id == 1;
  const isBssUser = user.user_group_id == 2;

  const [darkMode, setDarkMode] = useState(
    document.body.classList.contains("full-dark") ? true : false
  );

  const handleThemeMode = (mode) => {
    if (mode === "dark") {
      setDarkMode(true);
      document.body.classList.add("full-dark");
    } else {
      setDarkMode(false);
      document.body.classList.remove("full-dark");
    }
  };

  const handleMCQSheetDownload = () => {
    fetch(isBssUser ? bssMcqResSheet : eduMcqResSheet)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = isBssUser
          ? "MCQs Response Sheet Template - BSS"
          : "MCQs Response Sheet Template - TE";
        a.click();

        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error);
      });
  };

  const handleConvertToWord = () => {
    const url = "https://smallpdf.com/pdf-to-word";
    window.open(url, "_blank");
  };

  return (
    <div className={"tab-pane active show"} id="menu">
      <Nav id="left-sidebar-nav" className="sidebar-nav">
        <ul id="main-menu" className="metismenu">
          <li className="" id="dashboardContainer">
            <a
              className="has-arrow"
              onClick={() => activeMenuTabContainer("dashboardContainer")}
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              <i className="icon-home"></i> <span>Dashboard</span>
            </a>
            <ul className="collapse">
              <MenuLink activeKey={activeKey} href="/" title="Stats" />
              <MenuLink
                activeKey={activeKey}
                href="/about-us"
                title="About Us"
              />
            </ul>
          </li>

          <li className="" id="tourManagerContainer">
            <a
              className="has-arrow"
              onClick={() => activeMenuTabContainer("tourManagerContainer")}
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              <i className="icon-grid"></i> <span>Take a Tour</span>
            </a>
            <ul className="collapse">
              <MenuLink
                activeKey={activeKey}
                href="/taketour/paper-generation"
                title="Generate Paper (Tutorial)"
              />
              <MenuLink
                activeKey={activeKey}
                href="/taketour/reports-tutorial"
                title="Generate & View Reports (Tutorial)"
              />
              <MenuLink
                activeKey={activeKey}
                href="/taketour/lodge-complaint"
                title="Lodge a Complaint (Tutorial)"
              />
              <MenuLink
                activeKey={activeKey}
                href="/taketour/rate-review"
                title="Rate & Reviews (Tutorial)"
              />
            </ul>
          </li>

          {defaultAdminPermissions.includes(user?.rolename?.toLowerCase()) && (
            <li className="" id="adminContainer">
              <a
                className="has-arrow"
                onClick={() => activeMenuTabContainer("adminContainer")}
                style={{ userSelect: "none", cursor: "pointer" }}
              >
                <i className="icon-user"></i> <span>Admin</span>
              </a>
              <ul className="collapse">
                <MenuLink
                  activeKey={activeKey}
                  href="/campuses"
                  title="Add Campuses"
                />

                {isEduUser && (
                  <MenuLink
                    activeKey={activeKey}
                    href="/users"
                    title="Register Users"
                  />
                )}

                {isBssUser && (
                  <MenuLink
                    activeKey={activeKey}
                    href="/campus-users"
                    title="Register Users"
                  />
                )}

                <MenuLink
                  activeKey={activeKey}
                  href="/broadcasts"
                  title="Send Notifcations"
                />
              </ul>
            </li>
          )}
          {/* School to create Teachers Account */}
          {isBssUser &&
            user?.rolename?.toLowerCase() === USER_ROLES.school.toLowerCase() &&
            defaultPaperGeneratePermissions.includes(
              user?.rolename?.toLowerCase()
            ) &&
            user?.rolename?.toLowerCase() && (
              <li className="" id="CreateTeacherContainer">
                <a
                  className="has-arrow"
                  onClick={() =>
                    activeMenuTabContainer("CreateTeacherContainer")
                  }
                  style={{ userSelect: "none", cursor: "pointer" }}
                >
                  <i className="icon-user"></i> <span>Add Teacher</span>
                </a>
                <ul className="collapse">
                  <MenuLink
                    activeKey={activeKey}
                    href="/create-new-teacher"
                    title="Add New Teacher"
                  />
                </ul>
              </li>
            )}

          <li className="" id="MyAccount">
            <a
              className="has-arrow"
              onClick={() => activeMenuTabContainer("MyAccount")}
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              <i className="icon-lock"></i> <span>My Account</span>
            </a>
            <ul className="collapse">
              {USER_ROLES.school != user?.rolename?.toLowerCase() && (
                <MenuLink
                  activeKey={activeKey}
                  href="/my-feedbacks"
                  title="My Subject Feedbacks"
                />
              )}

              <MenuLink
                activeKey={activeKey}
                href="/myinbox"
                title="My Inbox"
              />

              <MenuLink
                activeKey={activeKey}
                href="/complaints"
                title="Lodge a Complaint"
              />

              <MenuLink
                activeKey={activeKey}
                href="/ask-questions"
                title="Ask a Question"
              />
              <MenuLink
                activeKey={activeKey}
                href="/rate-reviews"
                title="Rate & Review"
              />
            </ul>
          </li>

          {USER_ROLES.school != user?.rolename?.toLowerCase() && (
            <li className="" id="subjectManagerContainer">
              <a
                className="has-arrow"
                onClick={() =>
                  activeMenuTabContainer("subjectManagerContainer")
                }
                style={{ userSelect: "none", cursor: "pointer" }}
              >
                <i className="icon-pencil"></i> <span>Subject Manager</span>
              </a>
              <ul className="collapse">
                <MenuLink
                  activeKey={activeKey}
                  href="/chapters"
                  title="Chapters"
                />
                <MenuLink activeKey={activeKey} href="/topics" title="Topics" />
                <MenuLink activeKey={activeKey} href="/mcqs" title="MCQ'S" />
                <MenuLink
                  activeKey={activeKey}
                  href="/questions"
                  title="Questions"
                />
              </ul>
            </li>
          )}

          {defaultPaperGeneratePermissions.includes(
            user?.rolename?.toLowerCase()
          ) && (
            <li className="" id="paperManagerContainer">
              <a
                className="has-arrow"
                onClick={() => activeMenuTabContainer("paperManagerContainer")}
                style={{ userSelect: "none", cursor: "pointer" }}
              >
                <i className="icon-docs"></i> <span>Paper Manager</span>
              </a>
              <ul className="collapse">
                <MenuLink
                  activeKey={activeKey}
                  href="/papers/create"
                  title="Create Your Paper"
                />
                <MenuLink
                  activeKey={activeKey}
                  href="/papers"
                  title="View Papers"
                />
                 <MenuLink
                  activeKey={activeKey}
                  onClick={handleConvertToWord}
                  title="Convert PDF to Word"
                />
                <MenuLink
                  title="MCQ's Response Sheet"
                  onClick={handleMCQSheetDownload}
                />
              </ul>
            </li>
          )}

          {defaultPaperGeneratePermissions.includes(
            user?.rolename?.toLowerCase()
          ) && (
            <li className="" id="reportContainer">
              <a
                className="has-arrow"
                onClick={() => activeMenuTabContainer("reportContainer")}
                style={{ userSelect: "none", cursor: "pointer" }}
              >
                <i className="icon-anchor"></i> <span>Performance Reports</span>
              </a>
              <ul className="collapse">
                <MenuLink
                  activeKey={activeKey}
                  href="/reports/papers"
                  title="Paper Reports"
                />
              </ul>
            </li>
          )}

          <li className="" id="feedbackManagerContainer">
            <a
              className="has-arrow"
              onClick={() => activeMenuTabContainer("feedbackManagerContainer")}
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              <i className="fa fa-comments-o" aria-hidden="true"></i>{" "}
              <span>Feedback & Reviews</span>
            </a>
            <ul className="collapse">
              <MenuLink
                activeKey={activeKey}
                href="/feedback/complaints"
                title="Lodge a Complaint"
              />

              <MenuLink
                activeKey={activeKey}
                href="/feedback/ask-questions"
                title="Ask a Question"
              />

              <MenuLink
                activeKey={activeKey}
                href="/feedback/rate-reviews"
                title="Rate & Review"
              />
            </ul>
          </li>

          <li className="" id="settingManagerContainer">
            <a
              className="has-arrow"
              onClick={() => activeMenuTabContainer("settingManagerContainer")}
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              <i className="icon-settings"></i> <span>Settings</span>
            </a>
            <ul className="collapse">
              <MenuLink
                activeKey={activeKey}
                href="/change-password"
                title="Change Password"
              />

              <MenuLink
                title={darkMode ? "Light Mode" : "Dark Mode"}
                onClick={() => handleThemeMode(darkMode ? "light" : "dark")}
              />
            </ul>
          </li>
        </ul>
      </Nav>
    </div>
  );
};

export default MenuList;
