import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";
import CustomPagination from "components/CustomPagination ";
import TableFilters from "components/TableFilters";

// components
import PageHeader from "components/BreadCrumb";

// hooks
import { useDeleteQuestion, useGetQuestions } from "hooks/api/subject";
import { useTable } from "hooks/custom/useTable";

// context
import { useAuthContext } from "context/auth.context";

//
import {
  dataReviewUser,
  defaultAdminPermissions,
  defaultEntryPermissions,
} from "constant";
import { CSVLink } from "react-csv";

const Question = () => {
  const history = useHistory();
  const { user } = useAuthContext();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const { data: questions, refetch, isLoading } = useGetQuestions();
  const deleteQuestion = useDeleteQuestion(refetch);
  return (
    <div className="container-fluid">
      <PageHeader HeaderText="Questions" Breadcrumb={[{ name: "Questions" }]} />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>All Questions</h2>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CSVLink filename={"all-questions.csv"} data={rows}>
              {" "}
              <button type="button" className="btn btn-primary">
                <span className="mx-1">Export Questions</span>
                <i className="fa fa-download"></i>
              </button>
            </CSVLink>

            {defaultEntryPermissions.includes(user.rolename.toLowerCase()) && (
              <Link to="/questions/create">
                <button
                  type="button"
                  className="btn btn-success"
                  title="Create Question"
                >
                  <span className="sr-only">Create Question</span>
                  <i className="fa fa-plus mr-1"></i>{" "}
                  <span>Create Question</span>
                </button>
              </Link>
            )}
          </div>
        </div>

        <TableFilters
          data={questions?.data || []}
          setRows={setRows}
          setLoading={setLoading}
          searchKey="question"
          contextKey="QUESTION_FILTERS"
          isLoading={isLoading}
          showChapters
          showTopics
          showTypes
        />

        <div className="body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>TYPE</th>
                  <th>QUESTION</th>
                  <th>MARKS</th>
                  <th>CLASS</th>
                  <th>SUBJECT</th>
                  <th>CHAPTER</th>
                  <th>TOPIC</th>
                  <th>STATUS</th>
                  <th style={{ textAlign: "end" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "center",
                        height: "30vh",
                        width: "100%",
                      }}
                      className="visible-lg"
                    >
                      <i className="fa fa-spinner fa-4x fa-spin"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <>
                    {rows?.length > 0 ? (
                      rows
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row) => (
                          <tr key={row.id}>
                            <th scope="row">{row.type}</th>
                            <td
                              style={{
                                direction:
                                  row.subjectname == "Urdu" ? "rtl" : "",
                              }}
                            >
                              {["5", "12"].includes(`${row.subject_id}`) ? (
                                <MathJax math={row.question} />
                              ) : (
                                parse(`${row.question}`)
                              )}
                            </td>
                            <td>{row.marks}</td>
                            <td>{row.classname}</td>
                            <td>{row.subjectname}</td>
                            <td>{row.chapter_name}</td>
                            <td>{row.topic_name}</td>
                            <td
                              className={
                                row.status === 3 || row.status === 2
                                  ? "btn-primary"
                                  : row.status == 1
                                  ? "btn-success"
                                  : "btn-warning"
                              }
                            >
                              {row.status === 3
                                ? "Resolved"
                                : row.status == 2
                                ? "Feedback"
                                : row.status == 1
                                ? "Active"
                                : "De-active"}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary mr-1"
                                  title="View"
                                  onClick={() =>
                                    history.push(`/questions/${row.id}`)
                                  }
                                >
                                  <span className="sr-only">View</span>
                                  <i className="fa fa-eye"></i>
                                </button>

                                {defaultEntryPermissions.includes(
                                  user.rolename.toLowerCase()
                                ) && (
                                  <button
                                    type="button"
                                    className="btn btn-warning"
                                    title="Edit"
                                    onClick={() =>
                                      history.push(`/questions/edit/${row.id}`)
                                    }
                                  >
                                    <span className="sr-only">Edit</span>
                                    <i className="fa fa-edit"></i>
                                  </button>
                                )}

                                {defaultAdminPermissions.includes(
                                  user.rolename.toLowerCase()
                                ) && (
                                  <button
                                    type="button"
                                    className="btn btn-danger ml-1"
                                    title="Delete"
                                    onClick={() =>
                                      deleteQuestion.mutate(row.id)
                                    }
                                  >
                                    <span className="sr-only">Delete</span>
                                    <i className="fa fa-trash-o"></i>
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                            height: "30vh",
                            fontSize: "1.5rem",
                            width: "100%",
                          }}
                        >
                          Oops! No data found
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Question;
