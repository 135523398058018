// third party
import { useQuery, useMutation } from "@tanstack/react-query";

// hooks
import {
  getPaperTypes,
  getPapers,
  getMyPapers,
  getPaper,
  createPaper,
  createPunjabPaper,
  getPunjabPaper,
  deletePaper,
  getPaperSessions,
} from "api/v1/paper";

// custom hook
import { useToaster } from "hooks/custom/useToaster";

export const useGetPaperTypes = () => {
  return useQuery({
    queryKey: ["paper-types"],
    queryFn: () => getPaperTypes(),
    refetchOnWindowFocus: false,
  });
};

export const useGetPaperSessions = () => {
  return useQuery({
    queryKey: ["paper-sessions"],
    queryFn: () => getPaperSessions(),
    refetchOnWindowFocus: false,
  });
};

export const useCreatePaper = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createPaper,
    onSuccess: () => toasterSuccess("Created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useCreatePunjabPaper = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createPunjabPaper,
    onSuccess: () => toasterSuccess("Created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useGetPapers = () => {
  return useQuery({
    queryKey: ["papers"],
    queryFn: () => getPapers(),
    refetchOnWindowFocus: false,
  });
};

export const useGetMyPapers = () => {
  return useQuery({
    queryKey: ["my-papers"],
    queryFn: () => getMyPapers(),
    refetchOnWindowFocus: false,
  });
};

export const useGetPaper = (id) => {
  return useQuery({
    queryKey: ["papers", id],
    queryFn: () => getPaper(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useGetPunjabPaper = (id) => {
  return useQuery({
    queryKey: ["punjab", "papers", id],
    queryFn: () => getPunjabPaper(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useDeletePaper = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deletePaper,
    onSuccess: () => {
      refetch();
      toasterSuccess("Paper Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};
