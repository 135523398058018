import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import CustomPagination from "components/CustomPagination ";

// hooks
import { useTable } from "hooks/custom/useTable";
import { useGetNotifications } from "hooks/api/shared";

// context
import { useAuthContext } from "context/auth.context";

//
import { IMAGE_URL, defaultAdminPermissions } from "constant";
import { CSVLink } from "react-csv";

const BroadCast = () => {
  const { user } = useAuthContext();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const { data: notifications, refetch } = useGetNotifications();

  useEffect(() => {
    if(notifications?.data)  {setRows(notifications?.data)
      setLoading(false);
    };
  }, [notifications?.data]);

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Broadcasts"
        Breadcrumb={[{ name: "Broadcasts" }]}
      />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>All Broadcasts</h2>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {rows && rows.length > 0 ? (
              <CSVLink filename={"all-broadcast.csv"} data={rows}>
                <button type="button" className="btn btn-primary">
                  <span className="mx-1">Export Broadcast</span>
                  <i className="fa fa-download"></i>
                </button>
              </CSVLink>
            ) : (
              <></>
            )}

            {defaultAdminPermissions.includes(
              user?.rolename?.toLowerCase()
            ) && (
              <Link to="/Broadcasts/create">
                <button
                  type="button"
                  className="btn btn-success"
                  title="Create new broadcast"
                >
                  <span className="sr-only">Create new Broadcast</span>
                  <i className="fa fa-plus mr-1"></i>{" "}
                  <span>Create new Broadcast</span>
                </button>
              </Link>
            )}
          </div>
        </div>

        <div className="body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Message</th>
                <th>CREATED BY</th>
                <th style={{ textAlign: "end" }}>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {loading? <tr>
                  <td></td>
                  <td></td>
                  <td
                    style={{
                      textAlign: "center",
                      height: "30vh",
                      width: "100%",
                    }}
                    className="visible-lg"
                  >
                    <i className="fa fa-spinner fa-4x fa-spin"></i>
                  </td>
                  <td></td>
                </tr>:<> {rows?.length > 0 ? rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <tr key={row.id}>
                    <th scope="row">{row.id}</th>
                    <td>{row.text}</td>
                    <td>{row?.createdBy}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          title="View"
                          onClick={() => {
                            window.open(
                              `${IMAGE_URL}/notifications/${row.file}`,
                              "_blank"
                            );
                          }}
                        >
                          <span className="sr-only">View</span>
                          <i className="fa fa-eye"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                )):<tr>
                <td></td>
                <td></td>
                <td
                  style={{
                    textAlign: "center",
                    height: "30vh",
                    fontSize: "1.5rem",
                    width: "100%",
                  }}
                >
                  Oops! No data found
                </td>
                <td></td>
              </tr>}</>}
             
            </tbody>
          </table>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default BroadCast;
