import axios from "axios";
import { getToken } from "utils/token";

const client = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

const onSuccess = function (response) {
  console.debug("Request Successful!", response);
  return response.data;
};

const onError = function (error) {
  console.error("Request Failed:", error.config);
  if (error.response) {
    console.error("Status:", error.response.status);
    console.error("Data:", error.response.data);
    console.error("Headers:", error.response.headers);
  } else {
    console.error("Error Message:", error.message);
  }
  return Promise.reject({
    errMsg: !error?.response
      ? "Network Issue!"
      : error?.response?.data?.message ||
        error?.response?.data?.statusMessage ||
        error?.response?.data?.errors[0].param +
          " " +
          error?.response?.data?.errors[0].msg,
    status: error?.response?.status || "not status",
  });
};

client.interceptors.request.use((config) => {
  let token = getToken();
  token = token ? JSON.parse(token) : null;
  token = token?.access_token || "";
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

client.interceptors.response.use(onSuccess, onError);

export default client;
