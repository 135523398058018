import React, {useState} from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// components
import Notification from "./Notification";

// context
import { useAuthContext } from "context/auth.context";

//
import AppLogo from "../assets/images/logo.png";
import bssLogo from "../assets/images/bsslogo.png";

const SideMenuToggleBtn = ({ onPressSideMenuToggle }) => {
  return (
    <div className="navbar-btn">
      <button className="btn-toggle-offcanvas" onClick={onPressSideMenuToggle}>
        <i className="lnr lnr-menu fa fa-bars"></i>
      </button>
    </div>
  );
};

const Logo = ({ logoSrc }) => {
  return (
    <div className="navbar-brand">
      <a href="dashboard">
        <img
          src={logoSrc}
          alt="Logo"
          className="img-responsive logo"
          style={{ width: "135px" }}
        />
      </a>
    </div>
  );
};

const Navbar = ({
  toggleNotification,
  onPressNotification,
  onPressSideMenuToggle,
}) => {
  const history = useHistory();
  const { onLogout, user } = useAuthContext();

  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  const handleNewNotification = () => {
    setHasNewNotifications(true);
  };

  const handleNotificationClick = (e) => {
    e.preventDefault();
    setHasNewNotifications(false); // Resetting notification state
    onPressNotification();
  };

  // Determine the logo source based on user_group_id
  const logoSrc = user?.user_group_id === 2 ? bssLogo : AppLogo;

  return (
    <nav className="navbar navbar-fixed-top">
      <div className="container-fluid">
        <SideMenuToggleBtn onPressSideMenuToggle={onPressSideMenuToggle} />
        <Logo logoSrc={logoSrc} />

        <div className="navbar-right">
          <div id="navbar-menu">
            <ul className="nav navbar-nav">
              <li className={toggleNotification ? "show dropdown" : "dropdown"}>
                <a
                  href="#!"
                  className="dropdown-toggle icon-menu"
                  data-toggle="dropdown"
                  onClick={handleNotificationClick}
                >
                  <i className="icon-bell"></i>
                  {/* Conditionally render notification dot */}
                  {hasNewNotifications && <span className="notification-dot"></span>}
                </a>
                <Notification toggleNotification={toggleNotification} />
              </li>

              <li>
                <a
                  href="#!"
                  className="icon-menu"
                  onClick={(e) => {
                    e.preventDefault();
                    onLogout();
                    history.push("/login");
                  }}
                >
                  <i className="icon-login"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  toggleNotification: PropTypes.bool.isRequired,
  onPressNotification: PropTypes.func.isRequired,
  onPressSideMenuToggle: PropTypes.func.isRequired,
};

export default Navbar;
