import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";

// components
import PageHeader from "components/BreadCrumb";
import CustomPagination from "components/CustomPagination ";

// hooks
import { useTable } from "hooks/custom/useTable";

// context
import PaperReportsFilters from "components/TableFilters/PaperReportFilters";
import { paperReports } from "api/v1/paper";
import { useAuthContext } from "context/auth.context";

const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"],
];

const PaperReports = () => {
  const history = useHistory();
  const { user } = useAuthContext();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const submitReport = async (data) => {
    const res = await paperReports(data);
    setRows(res.data);
    setLoading(false);
  };

  return (
    <div className="container-fluid">
      <PageHeader Breadcrumb={[{ name: "Papers" }]} />

      <div className="card">
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2>Paper Reports</h2>

          <button
            type="button"
            className="btn btn-secondary"
            title="Generate Chapter"
          >
            <span className="sr-only">Export Report</span>
            <i className="fa fa-download"></i>
            <CSVLink
              data={[
                ["Paper #", "Paper Name", "Type", "Paper Date", "Created At"],
                ,
                ...rows.map((obj) => Object.values(obj)),
              ]}
              style={{ color: "#fff", marginLeft: "10px" }}
              filename={`Paper_Report_${new Date()}`}
            >
              Export Report
            </CSVLink>
          </button>
        </div>

        <PaperReportsFilters submitReport={submitReport} />

        <div className="body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Paper #</th>
                <th>Paper Name</th>
                <th>Type</th>
                <th>Paper Date</th>
                <th>Created At</th>
                <th style={{ textAlign: "end" }}>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td></td>
                  <td></td>
                  <td
                    style={{
                      textAlign: "center",
                      height: "30vh",
                      width: "100%",
                    }}
                    className="visible-lg"
                  >
                    <i className="fa fa-spinner fa-4x fa-spin"></i>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ) : (
                <>
                  {rows?.length > 0 ? (
                    rows
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row) => (
                        <tr key={row?.id}>
                          <td>{row.id} </td>
                          <td>{row.paper_title}</td>
                          <td>{row.type_name}</td>
                          <td>{new Date(row.paper_date).toDateString()}</td>
                          <td>{new Date(row.created_at).toDateString()}</td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              title="View"
                              onClick={() => history.push(`/papers/${row.id}`)}
                            >
                              <span className="sr-only">View</span>
                              <i className="fa fa-eye"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td
                        style={{
                          textAlign: "center",
                          height: "30vh",
                          fontSize: "1.5rem",
                          width: "100%",
                        }}
                      >
                        Oops! No data found
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PaperReports;
