import React, { useState, useEffect } from "react";

// packages
import { Modal } from "react-bootstrap";

const AddRemarks = ({ data, addRemarks }) => {
  const [modal, setModal] = useState(false);
  const [remarks, setRemarks] = useState(data?.remarks);
  const handleClose = () => setModal(false);

  useEffect(() => {
    setRemarks(data?.remarks);
  }, [data]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        title="Add Remarks"
        onClick={() => setModal(true)}
      >
        <span className="sr-only">Add Remarks</span>
        <i className="fa fa-edit"></i>
      </button>

      <Modal size="md" centered show={modal} onHide={handleClose}>
        <Modal.Body>
          <div className="header mb-4">
            <h4>Add Remarks</h4>
          </div>

          <div className="input-group">
            <textarea
              placeholder={"Write"}
              className={`form-control custom-text-area-height`}
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </div>

          <button
            className="btn btn-primary mt-4"
            style={{ width: "100%" }}
            onClick={() => {
              addRemarks(data.id, remarks);
              setModal(false);
            }}
          >
            {data?.remarks ? "Update" : "Add"}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddRemarks;
