// third party
import { useQuery, useMutation } from "@tanstack/react-query";

// hooks
import {
  getCampuses,
  getCampus,
  createCampus,
  deleteCampus,
  updateCampus,
} from "api/v1/campus";

// custom hook
import { useToaster } from "hooks/custom/useToaster";

export const useCreateCampus = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createCampus,
    onSuccess: () => toasterSuccess("Campus created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useGetCampuses = () => {
  return useQuery({
    queryKey: ["campuses"],
    queryFn: () => getCampuses(),
    refetchOnWindowFocus: false,
  });
};

export const useGetCampus = (id) => {
  return useQuery({
    queryKey: ["campuses", id],
    queryFn: () => getCampus(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useUpdateCampus = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateCampus,
    onSuccess: () => toasterSuccess("Updated!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useDeleteCampus = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteCampus,
    onSuccess: () => {
      refetch();
      toasterSuccess("Campus Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};
