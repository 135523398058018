import React, { useState } from "react";

// components
import { CustomTextField, CustomTable } from "./CustomComponent";
import QuestionsModal from "components/Modal/Questions";

// packages
import Select from "react-dropdown-select";

export default function Section({
  title,
  board_id,
  class_id,
  subject_id,
  sectionState,
  setSectionState,
  longQuestionStart,
  chaptersOptions,
  randomSelection,
}) {
  return (
    <>
      <div className="card">
        <div className="header">
          <h2>{title}</h2>
        </div>

        <div className="body">
          <div className="row clearfix">
            <div className="col-12 mb-4">
              <CustomTextField
                label={
                  title == "Part-I"
                    ? "Short Question Statement"
                    : "Long Question Statement"
                }
                placeholder="Enter note"
                value={sectionState?.note || ""}
                disabled={!!sectionState?.note_disabled}
                onChange={(e) =>
                  setSectionState({ ...sectionState, note: e.target.value })
                }
              />
            </div>

            <div className="col-6 mb-4">
              <CustomTextField
                label="Marks"
                placeholder="Enter marks"
                value={sectionState?.marks || ""}
                disabled={!!sectionState?.marks_disabled}
                onChange={(e) =>
                  setSectionState({ ...sectionState, marks: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>

      {sectionState?.questions?.map((ele, index) => (
        <Questions
          key={index}
          index={index}
          longQuestionStart={longQuestionStart}
          data={ele}
          board_id={board_id}
          class_id={class_id}
          subject_id={subject_id}
          sectionState={sectionState}
          setSectionState={setSectionState}
          chaptersOptions={chaptersOptions}
          randomSelection={randomSelection}
        />
      ))}
    </>
  );
}

const Questions = ({
  index,
  data,
  board_id,
  class_id,
  subject_id,
  sectionState,
  setSectionState,
  longQuestionStart,
  chaptersOptions,
  randomSelection,
}) => {
  const [selectedChapters, setSelectedChapters] = useState([]);

  return (
    <>
      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>
            Q.
            {longQuestionStart ? longQuestionStart + index + 2 : index + 2}
          </h2>

          {/* <button
            type="button"
            className="btn btn-warning"
            title="Delete"
            onClick={() =>
              setSectionState({
                ...sectionState,
                questions: sectionState?.questions?.filter(
                  (que) => que.id !== data.id
                ),
              })
            }
          >
            <span className="sr-only">Delete</span>
            <i className="fa fa-trash-o"></i>
          </button> */}
        </div>

        <div className="body">
          <div className="row clearfix">
            <div className="col-12  mb-4 form-group">
              <div className="input-group-prepend">
                <label className="input-group-text">Select Chapters</label>
              </div>
              <Select
                className="js-states "
                placeholder=""
                options={chaptersOptions}
                values={chaptersOptions.filter((ele) =>
                  selectedChapters.includes(ele.value)
                )}
                multi={true}
                searchable={true}
                onChange={(values) => {
                  setSelectedChapters(values.map((ele) => ele.value));
                  setSectionState({
                    ...sectionState,
                    questions: sectionState?.questions?.map((que) => {
                      if (que.id === data.id) {
                        return {
                          ...que,
                          chapterIds: values.map((ele) => ele.value),
                        };
                      } else {
                        return que;
                      }
                    }),
                  });
                }}
              />
            </div>

            <div className="col-6 mb-4">
              <CustomTextField
                label="Question Statement"
                placeholder="Enter note"
                value={data?.note || ""}
                disabled={!!data?.note_disabled}
                onChange={(e) =>
                  setSectionState({
                    ...sectionState,
                    questions: sectionState?.questions?.map((que) => {
                      if (que.id === data.id) {
                        return { ...que, note: e.target.value };
                      } else {
                        return que;
                      }
                    }),
                  })
                }
              />
            </div>

            <div className="col-6 mb-4">
              <CustomTextField
                label="Marks"
                placeholder="Enter marks"
                value={data?.marks || ""}
                disabled={!!data?.marks_disabled}
                onChange={(e) =>
                  setSectionState({
                    ...sectionState,
                    questions: sectionState?.questions?.map((que) => {
                      if (que.id === data.id) {
                        return { ...que, marks: e.target.value };
                      } else {
                        return que;
                      }
                    }),
                  })
                }
              />
            </div>

            {!randomSelection ? (
              <div className="col-6">
                <QuestionsModal
                  type={longQuestionStart ? "long" : "short"}
                  board_id={board_id}
                  class_id={class_id}
                  subject_id={subject_id}
                  selectedChapters={selectedChapters}
                  selectedQuestions={data?.parts || []}
                  setSelectedQuestions={(seletecQuestionsArray) => {
                    setSectionState({
                      ...sectionState,
                      questions: sectionState?.questions?.map((que) => {
                        if (que.id === data.id) {
                          return { ...que, parts: seletecQuestionsArray };
                        } else {
                          return que;
                        }
                      }),
                    });
                  }}
                />
              </div>
            ) : (
              <div className="col-6 mb-4">
                <CustomTextField
                  label="No of Questions"
                  placeholder="Enter no of questions"
                  value={data?.questions || ""}
                  onChange={(e) =>
                    setSectionState({
                      ...sectionState,
                      questions: sectionState?.questions?.map((que) => {
                        if (que.id === data.id) {
                          return { ...que, questions: e.target.value };
                        } else {
                          return que;
                        }
                      }),
                    })
                  }
                />
              </div>
            )}
          </div>
        </div>

        {!randomSelection && sectionState?.questions?.length > 0 && (
          <CustomTable
            rows={data?.parts || []}
            rowSelect={(rowSelectId) =>
              setSectionState({
                ...sectionState,
                questions: sectionState?.questions?.map((que) => {
                  if (que.id === data.id) {
                    return {
                      ...que,
                      parts: data?.parts.filter(
                        (quePart) => quePart.id !== rowSelectId
                      ),
                    };
                  } else {
                    return que;
                  }
                }),
              })
            }
            column="question"
          />
        )}
      </div>
    </>
  );
};

const CustomCheckbox = ({ name, defaultChecked, onChange, value, label }) => {
  return (
    <div key={name} className="form-group">
      <label htmlFor={name} className="fancy-checkbox">
        <input
          type="checkbox"
          id={name}
          name={name}
          defaultChecked={defaultChecked}
          value={value}
          onChange={onChange}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};
