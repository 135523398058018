import React from "react";
import "./GeneratePaper.css";
import PageHeader from "components/BreadCrumb";
import { useAuthContext } from "context/auth.context";

export default function GeneratePaper() {
  const { user } = useAuthContext();
  // Punjab Board
  //Grade IX

  const pdfPunjabAutoIX = [
    "Biology-Exam-Paper-Grade-IX-Punjab-Board(Auto).pdf",
    "Chemistry-Exam-Paper-Grade-IX-Punjab-Board(Auto).pdf",
    "English-Exam-Paper-Grade-IX-Punjab-Board(Auto).pdf",
    "Mathematics-Exam-Paper-Grade-IX-Punjab-Board(Auto).pdf",
    "Physics-Exam-Paper-Grade-IX-Punjab-Board(Auto).pdf",
  ];
  const pdfPunjabManualIX = [
    "Biology-Exam-Paper-Grade-IX-Punjab-Board(Manual).pdf",
    "Chemistry-Exam-Paper-Grade-IX-Punjab-Board(Manual).pdf",
    "English-Exam-Paper-Grade-IX-Punjab-Board(Manual).pdf",
    "Mathematics-Exam-Paper-Grade-IX-Punjab-Board(Manual).pdf",
    "Physics-Exam-Paper-Grade-IX-Punjab-Board(Manual).pdf",
  ];

  //Grade X

  const pdfPunjabAutoX = [
    "Biology-Exam-Paper-Grade-X-Punjab-Board(Auto).pdf",
    "Chemistry-Exam-Paper-Grade-X-Punjab-Board(Auto).pdf",
    "English-Exam-Paper-Grade-X-Punjab-Board(Auto).pdf",
    "Mathematics-Exam-Paper-Grade-X-Punjab-Board(Auto).pdf",
    "Physics-Exam-Paper-Grade-X-Punjab-Board(Auto).pdf",
  ];
  const pdfPunjabManualX = [
    "Biology-Exam-Paper-Grade-X-Punjab-Board(Manual).pdf",
    "Chemistry-Exam-Paper-Grade-X-Punjab-Board(Manual).pdf",
    "English-Exam-Paper-Grade-X-Punjab-Board(Manual).pdf",
    "Mathematics-Exam-Paper-Grade-X-Punjab-Board(Manual).pdf",
    "Physics-Exam-Paper-Grade-X-Punjab-Board(Manual).pdf",
  ];

  //Sindh Board
  //Grade IX
  const pdfSindhAutoIX = [
    "Biology-Exam-Paper-Grade-IX-Sindh-Board(Auto).pdf",
    "Chemistry-Exam-Paper-Grade-IX-Sindh-Board(Auto).pdf",
    "English-Exam-Paper-Grade-IX-Sindh-Board(Auto).pdf",
    "Mathematics-Exam-Paper-Grade-IX-Sindh-Board(Auto).pdf",
    "Physics-Exam-Paper-Grade-IX-Sindh-Board(Auto).pdf",
  ];
  const pdfSindhManualIX = [
    "Biology-Exam-Paper-Grade-IX-Sindh-Board(Manual).pdf",
    "Chemistry-Exam-Paper-Grade-IX-Sindh-Board(Manual).pdf",
    "English-Exam-Paper-Grade-IX-Sindh-Board(Manual).pdf",
    "Mathematics-Exam-Paper-Grade-IX-Sindh-Board(Manual).pdf",
    "Physics-Exam-Paper-Grade-IX-Sindh-Board(Manual).pdf",
  ];

  //Grade X

  const pdfSindhAutoX = [
    "Biology-Exam-Paper-Grade-X-Sindh-Board(Auto).pdf",
    "Chemistry-Exam-Paper-Grade-X-Sindh-Board(Auto).pdf",
    "English-Exam-Paper-Grade-X-Sindh-Board(Auto).pdf",
    "Mathematics-Exam-Paper-Grade-X-Sindh-Board(Auto).pdf",
    "Physics-Exam-Paper-Grade-X-Sindh-Board(Auto).pdf",
  ];
  const pdfSindhManualX = [
    "Biology-Exam-Paper-Grade-X-Sindh-Board(Manual).pdf",
    "Chemistry-Exam-Paper-Grade-X-Sindh-Board(Manual).pdf",
    "English-Exam-Paper-Grade-X-Sindh-Board(Manual).pdf",
    "Mathematics-Exam-Paper-Grade-X-Sindh-Board(Manual).pdf",
    "Physics-Exam-Paper-Grade-X-Sindh-Board(Manual).pdf",
  ];

  return (
    <div className="container-fluid">
      <PageHeader
        Breadcrumb={[
          { name: "Tutorials", navigate: "/taketour/paper-generation" },
          { name: "Paper Generation Tutorial" },
        ]}
      />
      {/* Punjab Board */}
      {user?.boards_id == 2 && (
        <div className="card">
          <div className="header">
            <h2>Punjab Board Paper Generation Tutorials</h2>
            <br />
            <p>
              This is a pdf tutorial of how to generate papers in the system{" "}
            </p>
          </div>
          <div className="header">
            <h3>Grade IX</h3>
            <h2>Automatic</h2>
            <br />
            <table className="pdf-table">
              <thead>
                <tr>
                  <th>PDF Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pdfPunjabAutoIX.map((pdf, index) => (
                  <tr key={index}>
                    <td>{pdf}</td>
                    <td>
                      <a href={`/pdf/PaperGenPunjab/Auto/IX/${pdf}`} download>
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="header">
            <h2>Manual</h2>
            <br />
            <table className="pdf-table">
              <thead>
                <tr>
                  <th>PDF Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pdfPunjabManualIX.map((pdf, index) => (
                  <tr key={index}>
                    <td>{pdf}</td>
                    <td>
                      <a href={`/pdf/PaperGenPunjab/Manual/IX/${pdf}`} download>
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="header">
            <h3>Grade X</h3>
            <h2>Automatic</h2>
            <br />
            <table className="pdf-table">
              <thead>
                <tr>
                  <th>PDF Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pdfPunjabAutoX.map((pdf, index) => (
                  <tr key={index}>
                    <td>{pdf}</td>
                    <td>
                      <a href={`/pdf/PaperGenPunjab/Auto/X/${pdf}`} download>
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="header">
            <h2>Manual</h2>
            <br />
            <table className="pdf-table">
              <thead>
                <tr>
                  <th>PDF Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pdfPunjabManualX.map((pdf, index) => (
                  <tr key={index}>
                    <td>{pdf}</td>
                    <td>
                      <a href={`/pdf/PaperGenPunjab/Manual/X/${pdf}`} download>
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Sindh Board */}
      {user?.boards_id == 4 && (
        <div className="card">
          <div className="header">
            <h2>Sindh Board Paper Generation Tutorials</h2>
            <br />
            <p>
              This is a pdf tutorial of how to generate papers in the system{" "}
            </p>
          </div>
          <div className="header">
            <h3>Grade IX</h3>
            <h2>Automatic</h2>
            <br />
            <table className="pdf-table">
              <thead>
                <tr>
                  <th>PDF Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pdfSindhAutoIX.map((pdf, index) => (
                  <tr key={index}>
                    <td>{pdf}</td>
                    <td>
                      <a href={`/pdf/PaperGenSindh/Auto/IX/${pdf}`} download>
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="header">
            <h2>Manual</h2>
            <br />
            <table className="pdf-table">
              <thead>
                <tr>
                  <th>PDF Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pdfSindhManualIX.map((pdf, index) => (
                  <tr key={index}>
                    <td>{pdf}</td>
                    <td>
                      <a href={`/pdf/PaperGenSindh/Manual/IX/${pdf}`} download>
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="header">
            <h3>Grade X</h3>
            <h2>Automatic</h2>
            <br />
            <table className="pdf-table">
              <thead>
                <tr>
                  <th>PDF Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pdfSindhAutoX.map((pdf, index) => (
                  <tr key={index}>
                    <td>{pdf}</td>
                    <td>
                      <a href={`/pdf/PaperGenSindh/Auto/X/${pdf}`} download>
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="header">
            <h2>Manual</h2>
            <br />
            <table className="pdf-table">
              <thead>
                <tr>
                  <th>PDF Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pdfSindhManualX.map((pdf, index) => (
                  <tr key={index}>
                    <td>{pdf}</td>
                    <td>
                      <a href={`/pdf/PaperGenSindh/Manual/X/${pdf}`} download>
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
