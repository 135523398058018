import React from "react";
const infoStyle = { border: "1px solid black", padding: "0.1rem 1rem " };

const PaperStudentEntryPunjab = ({ boardCheck, data, subjectCheck }) => {
  return (
    <div className="font-weight-bold">
      <div
        className=""
        style={{
          margin: "0 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>Subject: {data?.paperMcqs[0]?.subject_name}</div>
        <div>Campus: ________________</div>
        <div>Roll No: ________________</div>
      </div>
      {(subjectCheck === 16 ||
        (boardCheck === 1 && subjectCheck === 13) ||
        (boardCheck === 1 && subjectCheck === 6) ||
        (boardCheck === 1 && subjectCheck === 7) ||
        subjectCheck === 12) ? (
        <div
          className=""
          style={{
            margin: "0 0px 20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Date: ________________</div>
          <div>Section: ________________</div>
        </div>
      ):<></>}
    </div>
  );
};

export default PaperStudentEntryPunjab;
